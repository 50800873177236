import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { media } from 'Src/app/Styles/Theme';

const Wrapper = styled.div`
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  & video {
    outline: none;
  }
`;

const Bg = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #8cbcca;
  & ~ div {
    max-width: 90vw;
    max-height: 65vw;
  }
  &:hover {
    border-color: #0b4b69;
    &:after {
      background: #0b4b69;
    }
    &:before {
      background: #0b4b69;
    }
  }
  &:after {
    content: '';
    position: absolute;
    background: #8cbcca;
    width: 1px;
    height: 15px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
  }
  &:before {
    content: '';
    position: absolute;
    background: #8cbcca;
    width: 1px;
    height: 15px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(-45deg);
  }
`;

const PlayerContainer = styled.div`
  position: relative;
  padding-top: 10px;
  & video {
    max-width: 90vw;
  }
  ${media.tablet`
    padding:30px;
    max-width: none;
  `}
`;
const VideoPlayer = ({ url, close }) => {
  if (url) {
    return ReactDOM.createPortal(
      <Wrapper>
        <Bg />
        <PlayerContainer>
          <Close onClick={() => close(false)} />
          <ReactPlayer
            width={760}
            height={430}
            style={{ zIndex: '4' }}
            url={url}
            playing
            controls
          />
        </PlayerContainer>
      </Wrapper>,
      document.body,
    );
  }
  return null;
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  close: PropTypes.func.isRequired,
};
