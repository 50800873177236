import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import CardApp from './CardApp';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;
  padding: 20px;
  background: ${props =>
    props.invert ? props.theme.white : props.theme.primary_petrol};
  border-color: ${props =>
    props.invert
      ? props.theme.secondary_blue_light
      : props.theme.primary_petrol};
  ${media.tablet`
    padding: 20px;
  `}
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;

const VwApp = styled(Wrapper)`
  width: 100%;
  height: 168px;
  margin-bottom: 8px;
  ${media.tablet`
    margin-left: ${props => (props.fullWidth ? '16px' : '0')};
    width: 372px;
    height: 200px;
    margin-bottom: 0;
  `}
  ${media.widescreen`
    width: 392px;
  `}
  ${media.ultrawidescreen`
    width: 456px;
  `}
`;

const MobileApps = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <AppContainer>
      <VwApp>
        <CardApp app={Locales[lang].Contacts.vw} />
      </VwApp>
      <VwApp invert>
        <CardApp app={Locales[lang].Contacts.audi} invert />
      </VwApp>
      <VwApp>
        <CardApp app={Locales[lang].Contacts.skoda} />
      </VwApp>
    </AppContainer>
  );
};

export default MobileApps;
