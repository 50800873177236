import React, { useContext, useState } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import useInterval from 'Components/Common/Hooks/useInterval';
import useMobile from 'Components/Common/Hooks/useIsMobile';
import ProductTab from './ProductTab';
import TabContent from './TabContent';

const Col = styled.div`
  background: none;
  ${media.laptop`
    background: ${props => props.theme.bg_color};
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  ${media.tablet`
    padding: 0;
  `}
  ${media.laptop`
    width: 944px;
    padding: 64px 0 128px;
  `}
  ${media.widescreen`
    width: 1016px;
    padding: 80px 0;
  `}
  ${media.ultrawidescreen`
    width: 1176px;
    padding: 136px 0;
  `}
`;

const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${props => props.theme.bg_color};
  ${media.laptop`
    width: 944px;
    flex-direction: row;
  `}
  ${media.widescreen`
    width: 1016px;
  `}
  ${media.ultrawidescreen`
    width: 1176px;
  `}
`;

const Title = styled.h2`
  display: none;
  width: 100%;
  border: 0;
  margin: 0;
  padding-bottom: 0;
  margin-bottom: 32px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  letter-spacing: 0.02em;
  color: ${props => props.theme.primary_petrol};
  border-bottom: 0;
  ${media.laptop`
    display: block;
    padding-bottom: 32px;
    font-size: 30px;
    border-bottom: 1px solid ${props => props.theme.secondary_petrol};
  `}
  ${media.widescreen`
    padding-bottom: 64px;
    margin-bottom: 72px;
  `}
  ${media.ultrawidescreen`
    font-size: 40px;
    line-height: 50px;
  `}
`;

const Tabs = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 16px;
  background: ${props => props.theme.primary_petrol};
  overflow-x: scroll;
  ${media.laptop`
    width: 384px;
    height: 480px;
    padding: 20px 58px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-x: none;
  `}
  ${media.widescreen`
    width: 392px;
    height: 480px;
  `}
  ${media.ultrawidescreen`
    width: 456px;
    height: 542px;
  `}
`;

const Media = () => {
  const {
    state: { lang },
    dispatch,
  } = useContext(MainContext);

  const data = Locales[lang].Media.items;
  const delay = 3000;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const amountTabs = data.length;
  const isMobile = useMobile();

  useInterval(
    () => {
      setCurrentIndex(currentIndex < amountTabs - 1 ? currentIndex + 1 : 0);
    },
    !isMobile && isRunning ? delay : null,
  );

  return (
    <Col>
      <Wrapper>
        <Title
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Media.title,
          }}
        />
        <WrapperContent
          onMouseEnter={() => setIsRunning(false)}
          onMouseLeave={() => setIsRunning(true)}
        >
          <Tabs>
            {data.map((item, index) => (
              <ProductTab
                index={index}
                title={item.title}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                key={item.title}
              />
            ))}
          </Tabs>
          <TabContent content={data[currentIndex]} dispatch={dispatch} />
        </WrapperContent>
      </Wrapper>
    </Col>
  );
};
export default Media;
