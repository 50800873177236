import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import MainContext from 'Src/app/MainContextProvider';
import { media } from 'Src/app/Styles/Theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 510px;
  padding: 32px 16px;
  ${media.laptop`
    width: 480px;
    height: auto;
    padding: 0;
    align-items: flex-start;
    justify-content: space-between;
  `}
  ${media.widescreen`
    width: 496px;
  `}
  ${media.ultrawidescreen`
    width: 577px;
  `}
`;

const TitleContent = styled.h3`
  margin: 0 0 16px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  color: ${props => props.theme.primary_petrol};
  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
  ${media.widescreen`
    font-size: 30px;
    line-height: 40px;
  `}
`;

const DescriptionContent = styled.p`
  font-family: ${props => props.theme.fonts.font};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.petrol80};
`;

const Text = styled.p`
  font-family: ${props => props.theme.fonts.font};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.primary_petrol};
`;

const DownloadContent = styled.div``;

const DocsWrapper = styled.div`
  display: block;
  flex-wrap: wrap;
  justify-content: space-start;
`;

const Document = styled.a`
  display: inline-block;
  width: 140px;
  height: 90px;
  background-image: url(${props => props.bgSrc});
  background-size: cover;
  cursor: pointer;
  &:nth-child(1), &:nth-child(4) {
    margin-right: 8px;
  }
  ${media.mobile`
    width: 160px;
    height: 105px;
  `}
  ${media.phablet`
    margin-right: 8px;
    width: 215px;
    height: 140px;
  `}
  ${media.tablet`
    width: 198px;
    height: 136px;
  `}
  ${media.widescreen`
    width: 192px;
    height: 136px;
  `}
  ${media.ultrawidescreen`
    width: 224px;
    height: 155px;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.laptop`
    align-items: flex-start;
  `}
`;

const VideoContent = styled.div`
  display: flex;
  align-items: flex-end;
`;

const VideoCover = styled.img`
  width: 100%;
  cursor: pointer;
  ${media.phablet`
    width: 450px;
  `}
  ${media.ultrawidescreen`
    width: 577px;
  `}
`;

const TabContent = ({ content }) => {
  const { dispatch } = useContext(MainContext);
  const { title, description, downloadText, download, video } = content;
  return (
    <StaticQuery
      query={graphql`
        query {
          discs: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "discs.png" }
          ) {
            publicURL
          }
          oil: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "oil.png" }
          ) {
            publicURL
          }
          engine_oil: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "engine_oil.png" }
          ) {
            publicURL
          }
          brakes: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "brakes.png" }
          ) {
            publicURL
          }
          originalAudi_en: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_audi_en.jpg" }
          ) {
            publicURL
          }
          originalAudi_ru: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_audi.jpg" }
          ) {
            publicURL
          }
          originalSkoda_en: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_skoda_en.jpg" }
          ) {
            publicURL
          }
          originalSkoda_ru: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_skoda.jpg" }
          ) {
            publicURL
          }
          originalVw_en: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_vw_en.jpg" }
          ) {
            publicURL
          }
          originalVw_ru: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_vw.jpg" }
          ) {
            publicURL
          }
          originalVwCom: file(
            relativeDirectory: { regex: "Media/" }
            base: { eq: "original_vw_com.jpg" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <Wrapper>
            <Info>
              <TitleContent>{title}</TitleContent>
              <DescriptionContent>{description}</DescriptionContent>
            </Info>
            {download && (
              <DownloadContent>
                <Text>{downloadText}</Text>
                <DocsWrapper>
                  {download.map((item, index) => (
                    <Fragment key={item.cover}>
                      <Document
                        target="_blank"
                        href={item.href}
                        bgSrc={data[item.cover].publicURL}
                      />
                      {index === 1 && <br />}
                    </Fragment>
                  ))}
                </DocsWrapper>
              </DownloadContent>
            )}
            {video && (
              <VideoContent>
                <VideoCover
                  src={data[video.cover].publicURL}
                  isVideo
                  onClick={() => {
                    dispatch({ type: 'playVideo', payload: video.href });
                    if (video.name) {
                      window.dataLayer_kdx = window.dataLayer_kdx || [];
                      window.dataLayer_kdx.push({
                        event: 'send_ga',
                        eventCategory: 'Video',
                        eventAction: 'Play',
                        eventLabel: video.name,
                      });
                    }
                  }}
                />
              </VideoContent>
            )}
          </Wrapper>
        );
      }}
    />
  );
};

export default TabContent;
