import React, { useContext, useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Cookie from 'js-cookie';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import IconArrow from 'Src/assets/img/ico/icon_arrow_down.svg';
import Item from './Item';

const ArrowDown = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
  top: ${props => (props.isOpen ? '10px' : '0')};
  margin-bottom: 30px;
  transform-origin: center center;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s;
  opacity: 1;
  ${media.laptop`
    opacity: 0;
  `}
`;

const Title = styled.h2`
  width: auto;
  margin: 0 0 10px;
  font-size: 19px;
  line-height: 36px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  color: ${props => props.theme.primary_petrol};
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  ${media.mobile`
    font-size: 22px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
  ${media.laptop`
    letter-spacing: 0.01em;
  `}
  ${media.widescreen`
    font-size: 30px;
    line-height: 40px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const Description = styled.p`
  width: 100%;
  font-family: ${props => props.theme.fonts.font};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 auto 35px;
  ${media.phablet`
    width: 445px;
  `}
  ${media.laptop`
    width: 501px;
    font-size: 18px;
    line-height: 28px;
    margin: 0 auto 50px;
  `}
`;

const Stickers = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.laptop`
    flex-direction: row;
  `}
`;

const Content = styled.div`
  width: 100%;
  transition: all 300ms ${props => props.isOpen && 'ease-out'};
  transform-origin: top center;
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  max-height: ${props => (props.isOpen ? '680px' : '0')};
  overflow: hidden;
  margin-bottom: ${props => (props.isOpen ? '40px' : '0')};
  ${media.laptop`
    max-height: ${props => (props.isOpen ? '500px' : '0')};
    margin-bottom: ${props => (props.isOpen ? '64px' : '0')};
  `}
  ${media.ultrawidescreen`
    margin-bottom: ${props => (props.isOpen ? '88px' : '0')};
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 16px 0;
  background: ${props => props.theme.white};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    ${media.laptop`
      background-color: ${props =>
        !props.isOpen && props.theme.secondary_petrol};
    `}
    ${ArrowDown} {
      opacity: 1;
      top: ${props => (props.isOpen ? '5px' : '5px')};
    }

  }
  ${media.laptop`
    padding: 48px 16px 0;

  `}
  ${media.widescreen`
    padding: 64px 16px 0;
  `}
  ${media.ultrawidescreen`
    padding: 80px 16px 0;
  `}
`;

const Instruction = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const [isOpen, setOpen] = useState(false);
  const { title, description, sticker1, sticker2, sticker3 } = Locales[
    lang
  ].Instructions;

  useEffect(() => {
    if (Cookie.get('kdx_fv')) {
      setOpen(false);
    } else setOpen(true);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          GoodSticker: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "good_sticker.png" }
          ) {
            publicURL
          }
          BadStickerFirst: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "bad_sticker1.png" }
          ) {
            publicURL
          }
          BadStickerSecond: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "bad_sticker2.png" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <Wrapper>
            <Header isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
              <Title>{title}</Title>
              <ArrowDown isOpen={isOpen} />
            </Header>
            <Content isOpen={isOpen}>
              <Description
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <Stickers>
                <Item
                  key="good-sticker"
                  text={sticker1}
                  iconSrc={data.GoodSticker.publicURL}
                  alt="good-sticker"
                  isIconCheck
                />
                <Item
                  key="bad-sticker1"
                  text={sticker2}
                  iconSrc={data.BadStickerFirst.publicURL}
                  alt="bad-sticker"
                  isIconCheck={false}
                />
                <Item
                  key="bad-sticker2"
                  text={sticker3}
                  iconSrc={data.BadStickerSecond.publicURL}
                  alt="bad-sticker"
                  isIconCheck={false}
                />
                ,
              </Stickers>
            </Content>
          </Wrapper>
        );
      }}
    />
  );
};

export default Instruction;
