import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import styled from 'styled-components';
import Locales from 'Locales';
import { media } from 'Src/app/Styles/Theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px 16px;
  color: ${props => props.theme.white};
  background: ${props => props.theme.bg_color};
  ${media.tablet`
    width: 50%;
    height: 440px;
  `}
  ${media.laptop`
    width: 401px;
  `}
  
  ${media.desktop`
    height: 440px;
    width: 496px;
  `}
  ${media.ultrawidescreen`
    height: 608px;
    width: 576px;
  `}
`;

const Title = styled.h1`
  font-size: 24px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: ${props => props.theme.primary_petrol};
  margin: 0;
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 30px;
    letter-spacing: 0.01em;
  `}
  ${media.ultrawidescreen`
    font-size: 40px;
  `}
`;

const StyledDescription = styled.p`
  margin: 0;
  font-family: ${props => props.theme.fonts.font};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.primary_petrol};
  ${media.widescreen`
    font-size: 18px;
    line-height: 28px;
  `}
  ${media.ultrawidescreen`
    font-size: 20px;
    line-height: 35px;
  `}
`;

const Description = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const { h1, description } = Locales[lang].Header.Description;
  return (
    <Wrapper>
      <Title>{h1}</Title>
      <StyledDescription
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </Wrapper>
  );
};

export default Description;
