import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import { media } from 'Src/app/Styles/Theme';

const Link = styled.a`
  outline: none;
  display: flex;
  text-decoration: none;
  font-size: 20px;
  color: ${props => props.theme.white};
  position: relative;
  padding: 0;
  width: 48%;
  height: 123px;
  font-family: ${props => props.theme.fonts.font_head};
  border: 1px solid #335F7D;
  box-sizing: border-box;
  justify-content: flex-start;
  margin-bottom: 8px;
  &:nth-child(even) {
    margin-right: 8px;
  }
  ${media.tablet`
    /* width: 224px; */
    height: 172px;
  `}
  ${media.laptop`
    width: 224px;
    &:not(:last-child){
      margin-right: 16px;
    }
  `}
  ${media.widescreen`
    &:not(:last-child){
      margin-right: 40px;
    }
  `}
  ${media.ultrawidescreen`
    width: 336px;
    height: 224px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  `}

  &:hover .wrapper{
    opacity: 1;
    background: ${props => props.theme.secondary_petrol};
    transition: 0.3s;
    .title {
      color: ${props => props.theme.primary_petrol};
      svg {
        fill: ${props => props.theme.primary_petrol};
      }
    }
    .map {
      opacity: 1;
      color: ${props => props.theme.primary_petrol};
      svg {
        fill: ${props => props.theme.primary_petrol};
      }
    }
  }
  .map {
    position: absolute;
      bottom: 10px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    opacity: 1;
    color: ${props => props.theme.secondary_blue_light};
    transition: 0.3s;
    svg {
      vertical-align: bottom;
      fill: ${props => props.theme.secondary_blue_light};
    }
    ${media.laptop`
      position: static;
      font-size: 16px;
    `}
    ${media.desktop`
      opacity: 0;
      color: ${props => props.theme.primary_petrol};
      span svg {
        fill: ${props => props.theme.primary_petrol};
      }
      span:hover {
        color: ${props => props.theme.secondary_blue_dark};
        
      }
      &:hover svg {
        fill: ${props => props.theme.secondary_blue_dark};
      }
    `}
    ${media.ultrawidescreen`
      font-size: 22px;
    `}
  }
  .wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    outline: none;
    opacity: 1;
    ${media.mobile`
      padding: 15px;
    `}
  }
  .title {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.white};;
    vertical-align: bottom;
    ${media.laptop`
      font-size: 22px;
    `}
    ${media.ultrawidescreen`
      font-size: 24px;
    `}
    svg {
      position: relative;
      top: 6px;
      margin-left: 5px;
      fill: ${props => props.theme.white};
      ${media.mobile`
        margin-left: 10px;
      `}
      ${media.tablet`
        margin-left: 20px;
      `}

      &.commercial {
        position: absolute;
        left: 85px;
        top: 1px;
        ${media.laptop`
          left: 115px;
          top: 3px;
        `}
      }
    }
  }
`;

const Item = ({ title, href, classForCommercialName }) => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <Link target="_blank" href={href}>
      <div className="wrapper">
        <div className="title">
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <svg
            className={classForCommercialName}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22.172 10.956C21.9512 10.621 21.6108 10.395 21.2153 10.318L18.1664 9.73C17.7146 9.643 17.0129 9.299 16.6655 8.994L14.1557 6.787C13.6527 6.346 12.7352 6 12.0666 6H7.04694C6.33115 6 5.46978 6.432 5.04111 7.003L3.22702 9.431C2.8365 9.954 2.5293 10.87 2.5293 11.516V14.499C2.5293 15.326 3.20494 15.999 4.03518 15.999H4.58834C4.82125 17.139 5.83521 18 7.04795 18C8.43236 18 9.55876 16.878 9.55876 15.499C9.55876 14.12 8.43236 12.998 7.04795 12.998C5.83521 12.998 4.82125 13.859 4.58834 14.999H4.03518C3.7591 14.999 3.53322 14.774 3.53322 14.499V11.516C3.53322 11.079 3.76613 10.384 4.03317 10.029L5.84726 7.601C6.08117 7.286 6.65341 7 7.04694 7H12.0666C12.5002 7 13.1658 7.251 13.4911 7.537L16.0009 9.744C16.4818 10.166 17.3502 10.592 17.9766 10.713L21.0255 11.301C21.153 11.325 21.2625 11.398 21.3327 11.504C21.403 11.611 21.4271 11.74 21.398 11.867L20.8298 14.415C20.7625 14.721 20.4132 14.999 20.0979 14.999H19.5177C19.2847 13.859 18.2698 12.998 17.0581 12.998C15.8453 12.998 14.8303 13.859 14.5974 15H11.5224V16H14.5974C14.8314 17.14 15.8453 18 17.057 18C18.2698 18 19.2837 17.139 19.5167 15.999H20.0979C20.887 15.999 21.6379 15.398 21.8096 14.632L22.3778 12.084C22.4652 11.691 22.3919 11.29 22.172 10.956ZM7.04795 13.998C7.8792 13.998 8.55483 14.671 8.55483 15.499C8.55483 16.327 7.8792 17 7.04795 17C6.2167 17 5.54106 16.327 5.54106 15.499C5.54106 14.671 6.2167 13.998 7.04795 13.998ZM17.0581 17C16.3352 17 15.7309 16.491 15.5853 15.813V15.185C15.7309 14.508 16.3352 13.998 17.0581 13.998C17.8893 13.998 18.5649 14.671 18.5649 15.499C18.5649 16.327 17.8893 17 17.0581 17Z" />
          </svg>
        </div>
        <div className="map">
          <span>
            {Locales[lang].Dealers.map}
            <svg width="25" height="24" viewBox="0 0 25 24">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2132 12.9725L11.5787 15.7531L12.4161 16.5403L15.9686 12.7905C16.1679 12.5817 16.1783 12.2581 15.9974 12.0366L12.7248 8L11.8298 8.72173L14.3453 11.825L7.08025 11.825L7.08025 12.9725L14.2132 12.9725Z"
              />
            </svg>
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Item;

Item.defaultProps = {
  href: '#',
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
};
