import React, { useContext, useState, useEffect } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import Cookie from 'js-cookie';
import { media } from 'Src/app/Styles/Theme';
import Image1 from 'Src/assets/img/ico/marking/icon1.svg';
import Image2 from 'Src/assets/img/ico/marking/icon2.svg';
import Image3 from 'Src/assets/img/ico/marking/icon3.svg';
import Image4 from 'Src/assets/img/ico/marking/icon4.svg';
import Image5 from 'Src/assets/img/ico/marking/icon5.svg';
import Image6 from 'Src/assets/img/ico/marking/icon6.svg';
import IconArrow from 'Src/assets/img/ico/icon_arrow_down.svg';
import Item from './Item';

const ArrowDown = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
  top: ${props => (props.isOpen ? '10px' : '0')};
  margin-bottom: 30px;
  transform-origin: center center;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s;
  opacity: 1;
  ${media.laptop`
    opacity: 0;
  `}
`;

const Title = styled.h2`
  width: auto;
  margin: 0 0 10px;
  font-size: 19px;
  line-height: 36px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  color: ${props => props.theme.primary_petrol};
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  ${media.mobile`
    font-size: 22px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
  ${media.laptop`
    letter-spacing: 0.01em;
  `}
  ${media.widescreen`
    font-size: 30px;
    line-height: 40px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const ProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  transition: all 300ms ${props => props.isOpen && 'ease-out'};
  height: auto;
  overflow: hidden;
  ${media.laptop`
    border: 1px solid ${props =>
      props.isOpen ? props.theme.secondary_petrol : 'transparent'};
    flex-direction: row;
    height: 360px;
    padding: 60px 0 70px 70px;
  `}
  ${media.laptop`
    width: 820px;
    
    height: 360px;
  `}
  ${media.ultrawidescreen`
    width: 1080px;
    padding: 0 70px;
    height: 306px;
    padding: 70px 0 70px 70px;
  `}
`;

const Content = styled.div`
  width: 100%;
  transition: all 300ms ${props => props.isOpen && 'ease-out'};
  transform-origin: top center;
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  max-height: ${props => (props.isOpen ? '600px' : '0')};
  overflow: hidden;
  margin-bottom: ${props => (props.isOpen ? '40px' : '0')};
  ${media.laptop`
    margin-bottom: ${props => (props.isOpen ? '64px' : '0')};
    max-height: ${props => (props.isOpen ? '500px' : '0')};
  `}
  ${media.ultrawidescreen`
    margin-bottom: ${props => (props.isOpen ? '88px' : '0')};
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 16px 0;
  background: ${props => props.theme.white};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    ${media.laptop`
      background-color: ${props =>
        !props.isOpen && props.theme.secondary_petrol};
    `}
    ${ArrowDown} {
      opacity: 1;
      top: ${props => (props.isOpen ? '5px' : '5px')};
    }

  }
  ${media.laptop`
    padding: 48px 16px 0;

  `}
  ${media.widescreen`
    padding: 64px 16px 0;
  `}
  ${media.ultrawidescreen`
    padding: 80px 16px 0;
  `}
`;

const MarkingBlock = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const [isOpen, setOpen] = useState(true);
  const {
    title,
    description1,
    description2,
    description3,
    description4,
    description5,
    description6,
  } = Locales[lang].Marking;
  const dataItems = [
    {
      img: Image1,
      description: description1,
      video: `${process.env.GATSBY_MEDIA_CONTENT}/tormoza.mp4`,
      name: 'tormoza',
    },
    {
      img: Image2,
      description: description2,
      video: `${process.env.GATSBY_MEDIA_CONTENT}/diski.mp4`,
      name: 'diski',
    },
    {
      img: Image3,
      description: description3,
      video: `${process.env.GATSBY_MEDIA_CONTENT}/maslo.mp4`,
      name: 'maslo',
    },
    {
      img: Image4,
      description: description4,
      name: '',
    },
    {
      img: Image5,
      description: description5,
      name: '',
    },
    {
      img: Image6,
      description: description6,
      name: '',
    },
  ];

  useEffect(() => {
    if (Cookie.get('kdx_fv')) {
      setOpen(false);
    } else setOpen(true);
  }, []);

  const items = dataItems.map(item => {
    const { description, img, video, name } = item;
    return (
      <Item
        key={description}
        text={description}
        iconSrc={img}
        alt={description}
        video={video}
        name={name}
      />
    );
  });

  return (
    <Wrapper>
      <Header isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        <Title>{title}</Title>
        <ArrowDown isOpen={isOpen} />
      </Header>
      <Content isOpen={isOpen}>
        <ProductsWrapper isOpen={isOpen}>{items}</ProductsWrapper>
      </Content>
    </Wrapper>
  );
};

export default MarkingBlock;
