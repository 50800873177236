import React from 'react';

export default () => (
  <svg
    version="1"
    baseProfile="full"
    width="328"
    height="328"
    viewBox="0 0 328 328"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#fff" d="M0 0h328v328H0z" />
    <defs>
      <path id="a" d="M0 0h8v8H0z" />
    </defs>
    <use x="32" y="32" xlinkHref="#a" />
    <use x="32" y="40" xlinkHref="#a" />
    <use x="32" y="48" xlinkHref="#a" />
    <use x="32" y="56" xlinkHref="#a" />
    <use x="32" y="64" xlinkHref="#a" />
    <use x="32" y="72" xlinkHref="#a" />
    <use x="32" y="80" xlinkHref="#a" />
    <use x="32" y="96" xlinkHref="#a" />
    <use x="32" y="104" xlinkHref="#a" />
    <use x="32" y="120" xlinkHref="#a" />
    <use x="32" y="128" xlinkHref="#a" />
    <use x="32" y="144" xlinkHref="#a" />
    <use x="32" y="168" xlinkHref="#a" />
    <use x="32" y="176" xlinkHref="#a" />
    <use x="32" y="184" xlinkHref="#a" />
    <use x="32" y="240" xlinkHref="#a" />
    <use x="32" y="248" xlinkHref="#a" />
    <use x="32" y="256" xlinkHref="#a" />
    <use x="32" y="264" xlinkHref="#a" />
    <use x="32" y="272" xlinkHref="#a" />
    <use x="32" y="280" xlinkHref="#a" />
    <use x="32" y="288" xlinkHref="#a" />
    <use x="40" y="32" xlinkHref="#a" />
    <use x="40" y="80" xlinkHref="#a" />
    <use x="40" y="96" xlinkHref="#a" />
    <use x="40" y="104" xlinkHref="#a" />
    <use x="40" y="120" xlinkHref="#a" />
    <use x="40" y="136" xlinkHref="#a" />
    <use x="40" y="144" xlinkHref="#a" />
    <use x="40" y="152" xlinkHref="#a" />
    <use x="40" y="160" xlinkHref="#a" />
    <use x="40" y="176" xlinkHref="#a" />
    <use x="40" y="192" xlinkHref="#a" />
    <use x="40" y="208" xlinkHref="#a" />
    <use x="40" y="216" xlinkHref="#a" />
    <use x="40" y="224" xlinkHref="#a" />
    <use x="40" y="240" xlinkHref="#a" />
    <use x="40" y="288" xlinkHref="#a" />
    <use x="48" y="32" xlinkHref="#a" />
    <use x="48" y="48" xlinkHref="#a" />
    <use x="48" y="56" xlinkHref="#a" />
    <use x="48" y="64" xlinkHref="#a" />
    <use x="48" y="80" xlinkHref="#a" />
    <use x="48" y="96" xlinkHref="#a" />
    <use x="48" y="120" xlinkHref="#a" />
    <use x="48" y="136" xlinkHref="#a" />
    <use x="48" y="152" xlinkHref="#a" />
    <use x="48" y="168" xlinkHref="#a" />
    <use x="48" y="176" xlinkHref="#a" />
    <use x="48" y="184" xlinkHref="#a" />
    <use x="48" y="192" xlinkHref="#a" />
    <use x="48" y="208" xlinkHref="#a" />
    <use x="48" y="216" xlinkHref="#a" />
    <use x="48" y="224" xlinkHref="#a" />
    <use x="48" y="240" xlinkHref="#a" />
    <use x="48" y="256" xlinkHref="#a" />
    <use x="48" y="264" xlinkHref="#a" />
    <use x="48" y="272" xlinkHref="#a" />
    <use x="48" y="288" xlinkHref="#a" />
    <use x="56" y="32" xlinkHref="#a" />
    <use x="56" y="48" xlinkHref="#a" />
    <use x="56" y="56" xlinkHref="#a" />
    <use x="56" y="64" xlinkHref="#a" />
    <use x="56" y="80" xlinkHref="#a" />
    <use x="56" y="96" xlinkHref="#a" />
    <use x="56" y="112" xlinkHref="#a" />
    <use x="56" y="120" xlinkHref="#a" />
    <use x="56" y="136" xlinkHref="#a" />
    <use x="56" y="144" xlinkHref="#a" />
    <use x="56" y="152" xlinkHref="#a" />
    <use x="56" y="176" xlinkHref="#a" />
    <use x="56" y="184" xlinkHref="#a" />
    <use x="56" y="216" xlinkHref="#a" />
    <use x="56" y="224" xlinkHref="#a" />
    <use x="56" y="240" xlinkHref="#a" />
    <use x="56" y="256" xlinkHref="#a" />
    <use x="56" y="264" xlinkHref="#a" />
    <use x="56" y="272" xlinkHref="#a" />
    <use x="56" y="288" xlinkHref="#a" />
    <use x="64" y="32" xlinkHref="#a" />
    <use x="64" y="48" xlinkHref="#a" />
    <use x="64" y="56" xlinkHref="#a" />
    <use x="64" y="64" xlinkHref="#a" />
    <use x="64" y="80" xlinkHref="#a" />
    <use x="64" y="96" xlinkHref="#a" />
    <use x="64" y="104" xlinkHref="#a" />
    <use x="64" y="112" xlinkHref="#a" />
    <use x="64" y="160" xlinkHref="#a" />
    <use x="64" y="168" xlinkHref="#a" />
    <use x="64" y="176" xlinkHref="#a" />
    <use x="64" y="184" xlinkHref="#a" />
    <use x="64" y="200" xlinkHref="#a" />
    <use x="64" y="208" xlinkHref="#a" />
    <use x="64" y="216" xlinkHref="#a" />
    <use x="64" y="240" xlinkHref="#a" />
    <use x="64" y="256" xlinkHref="#a" />
    <use x="64" y="264" xlinkHref="#a" />
    <use x="64" y="272" xlinkHref="#a" />
    <use x="64" y="288" xlinkHref="#a" />
    <use x="72" y="32" xlinkHref="#a" />
    <use x="72" y="80" xlinkHref="#a" />
    <use x="72" y="144" xlinkHref="#a" />
    <use x="72" y="160" xlinkHref="#a" />
    <use x="72" y="176" xlinkHref="#a" />
    <use x="72" y="192" xlinkHref="#a" />
    <use x="72" y="208" xlinkHref="#a" />
    <use x="72" y="216" xlinkHref="#a" />
    <use x="72" y="240" xlinkHref="#a" />
    <use x="72" y="288" xlinkHref="#a" />
    <use x="80" y="32" xlinkHref="#a" />
    <use x="80" y="40" xlinkHref="#a" />
    <use x="80" y="48" xlinkHref="#a" />
    <use x="80" y="56" xlinkHref="#a" />
    <use x="80" y="64" xlinkHref="#a" />
    <use x="80" y="72" xlinkHref="#a" />
    <use x="80" y="80" xlinkHref="#a" />
    <use x="80" y="96" xlinkHref="#a" />
    <use x="80" y="112" xlinkHref="#a" />
    <use x="80" y="128" xlinkHref="#a" />
    <use x="80" y="144" xlinkHref="#a" />
    <use x="80" y="160" xlinkHref="#a" />
    <use x="80" y="176" xlinkHref="#a" />
    <use x="80" y="192" xlinkHref="#a" />
    <use x="80" y="208" xlinkHref="#a" />
    <use x="80" y="224" xlinkHref="#a" />
    <use x="80" y="240" xlinkHref="#a" />
    <use x="80" y="248" xlinkHref="#a" />
    <use x="80" y="256" xlinkHref="#a" />
    <use x="80" y="264" xlinkHref="#a" />
    <use x="80" y="272" xlinkHref="#a" />
    <use x="80" y="280" xlinkHref="#a" />
    <use x="80" y="288" xlinkHref="#a" />
    <use x="88" y="96" xlinkHref="#a" />
    <use x="88" y="104" xlinkHref="#a" />
    <use x="88" y="112" xlinkHref="#a" />
    <use x="88" y="128" xlinkHref="#a" />
    <use x="88" y="136" xlinkHref="#a" />
    <use x="88" y="184" xlinkHref="#a" />
    <use x="88" y="192" xlinkHref="#a" />
    <use x="88" y="200" xlinkHref="#a" />
    <use x="88" y="216" xlinkHref="#a" />
    <use x="88" y="224" xlinkHref="#a" />
    <use x="96" y="40" xlinkHref="#a" />
    <use x="96" y="56" xlinkHref="#a" />
    <use x="96" y="72" xlinkHref="#a" />
    <use x="96" y="80" xlinkHref="#a" />
    <use x="96" y="96" xlinkHref="#a" />
    <use x="96" y="112" xlinkHref="#a" />
    <use x="96" y="120" xlinkHref="#a" />
    <use x="96" y="128" xlinkHref="#a" />
    <use x="96" y="136" xlinkHref="#a" />
    <use x="96" y="144" xlinkHref="#a" />
    <use x="96" y="168" xlinkHref="#a" />
    <use x="96" y="184" xlinkHref="#a" />
    <use x="96" y="200" xlinkHref="#a" />
    <use x="96" y="216" xlinkHref="#a" />
    <use x="96" y="232" xlinkHref="#a" />
    <use x="96" y="240" xlinkHref="#a" />
    <use x="96" y="256" xlinkHref="#a" />
    <use x="96" y="264" xlinkHref="#a" />
    <use x="96" y="272" xlinkHref="#a" />
    <use x="96" y="280" xlinkHref="#a" />
    <use x="96" y="288" xlinkHref="#a" />
    <use x="104" y="56" xlinkHref="#a" />
    <use x="104" y="64" xlinkHref="#a" />
    <use x="104" y="72" xlinkHref="#a" />
    <use x="104" y="88" xlinkHref="#a" />
    <use x="104" y="96" xlinkHref="#a" />
    <use x="104" y="128" xlinkHref="#a" />
    <use x="104" y="136" xlinkHref="#a" />
    <use x="104" y="144" xlinkHref="#a" />
    <use x="104" y="152" xlinkHref="#a" />
    <use x="104" y="168" xlinkHref="#a" />
    <use x="104" y="224" xlinkHref="#a" />
    <use x="104" y="240" xlinkHref="#a" />
    <use x="104" y="256" xlinkHref="#a" />
    <use x="104" y="264" xlinkHref="#a" />
    <use x="112" y="32" xlinkHref="#a" />
    <use x="112" y="40" xlinkHref="#a" />
    <use x="112" y="56" xlinkHref="#a" />
    <use x="112" y="80" xlinkHref="#a" />
    <use x="112" y="88" xlinkHref="#a" />
    <use x="112" y="104" xlinkHref="#a" />
    <use x="112" y="112" xlinkHref="#a" />
    <use x="112" y="128" xlinkHref="#a" />
    <use x="112" y="152" xlinkHref="#a" />
    <use x="112" y="160" xlinkHref="#a" />
    <use x="112" y="168" xlinkHref="#a" />
    <use x="112" y="192" xlinkHref="#a" />
    <use x="112" y="224" xlinkHref="#a" />
    <use x="112" y="232" xlinkHref="#a" />
    <use x="112" y="264" xlinkHref="#a" />
    <use x="112" y="272" xlinkHref="#a" />
    <use x="112" y="288" xlinkHref="#a" />
    <use x="120" y="72" xlinkHref="#a" />
    <use x="120" y="88" xlinkHref="#a" />
    <use x="120" y="96" xlinkHref="#a" />
    <use x="120" y="144" xlinkHref="#a" />
    <use x="120" y="152" xlinkHref="#a" />
    <use x="120" y="160" xlinkHref="#a" />
    <use x="120" y="208" xlinkHref="#a" />
    <use x="120" y="216" xlinkHref="#a" />
    <use x="120" y="224" xlinkHref="#a" />
    <use x="120" y="272" xlinkHref="#a" />
    <use x="120" y="280" xlinkHref="#a" />
    <use x="120" y="288" xlinkHref="#a" />
    <use x="128" y="32" xlinkHref="#a" />
    <use x="128" y="48" xlinkHref="#a" />
    <use x="128" y="56" xlinkHref="#a" />
    <use x="128" y="72" xlinkHref="#a" />
    <use x="128" y="80" xlinkHref="#a" />
    <use x="128" y="104" xlinkHref="#a" />
    <use x="128" y="120" xlinkHref="#a" />
    <use x="128" y="128" xlinkHref="#a" />
    <use x="128" y="144" xlinkHref="#a" />
    <use x="128" y="168" xlinkHref="#a" />
    <use x="128" y="184" xlinkHref="#a" />
    <use x="128" y="192" xlinkHref="#a" />
    <use x="128" y="208" xlinkHref="#a" />
    <use x="128" y="232" xlinkHref="#a" />
    <use x="128" y="248" xlinkHref="#a" />
    <use x="128" y="256" xlinkHref="#a" />
    <use x="128" y="272" xlinkHref="#a" />
    <use x="128" y="288" xlinkHref="#a" />
    <use x="136" y="40" xlinkHref="#a" />
    <use x="136" y="56" xlinkHref="#a" />
    <use x="136" y="96" xlinkHref="#a" />
    <use x="136" y="112" xlinkHref="#a" />
    <use x="136" y="128" xlinkHref="#a" />
    <use x="136" y="136" xlinkHref="#a" />
    <use x="136" y="160" xlinkHref="#a" />
    <use x="136" y="168" xlinkHref="#a" />
    <use x="136" y="192" xlinkHref="#a" />
    <use x="136" y="200" xlinkHref="#a" />
    <use x="136" y="224" xlinkHref="#a" />
    <use x="136" y="240" xlinkHref="#a" />
    <use x="136" y="256" xlinkHref="#a" />
    <use x="144" y="48" xlinkHref="#a" />
    <use x="144" y="56" xlinkHref="#a" />
    <use x="144" y="72" xlinkHref="#a" />
    <use x="144" y="80" xlinkHref="#a" />
    <use x="144" y="88" xlinkHref="#a" />
    <use x="144" y="112" xlinkHref="#a" />
    <use x="144" y="128" xlinkHref="#a" />
    <use x="144" y="160" xlinkHref="#a" />
    <use x="144" y="176" xlinkHref="#a" />
    <use x="144" y="200" xlinkHref="#a" />
    <use x="144" y="208" xlinkHref="#a" />
    <use x="144" y="216" xlinkHref="#a" />
    <use x="144" y="224" xlinkHref="#a" />
    <use x="144" y="232" xlinkHref="#a" />
    <use x="144" y="256" xlinkHref="#a" />
    <use x="144" y="264" xlinkHref="#a" />
    <use x="144" y="272" xlinkHref="#a" />
    <use x="144" y="280" xlinkHref="#a" />
    <use x="152" y="40" xlinkHref="#a" />
    <use x="152" y="48" xlinkHref="#a" />
    <use x="152" y="72" xlinkHref="#a" />
    <use x="152" y="96" xlinkHref="#a" />
    <use x="152" y="112" xlinkHref="#a" />
    <use x="152" y="128" xlinkHref="#a" />
    <use x="152" y="144" xlinkHref="#a" />
    <use x="152" y="152" xlinkHref="#a" />
    <use x="152" y="160" xlinkHref="#a" />
    <use x="152" y="176" xlinkHref="#a" />
    <use x="152" y="192" xlinkHref="#a" />
    <use x="152" y="208" xlinkHref="#a" />
    <use x="152" y="240" xlinkHref="#a" />
    <use x="152" y="248" xlinkHref="#a" />
    <use x="152" y="272" xlinkHref="#a" />
    <use x="152" y="280" xlinkHref="#a" />
    <use x="152" y="288" xlinkHref="#a" />
    <use x="160" y="32" xlinkHref="#a" />
    <use x="160" y="40" xlinkHref="#a" />
    <use x="160" y="64" xlinkHref="#a" />
    <use x="160" y="80" xlinkHref="#a" />
    <use x="160" y="88" xlinkHref="#a" />
    <use x="160" y="96" xlinkHref="#a" />
    <use x="160" y="104" xlinkHref="#a" />
    <use x="160" y="128" xlinkHref="#a" />
    <use x="160" y="136" xlinkHref="#a" />
    <use x="160" y="144" xlinkHref="#a" />
    <use x="160" y="152" xlinkHref="#a" />
    <use x="160" y="168" xlinkHref="#a" />
    <use x="160" y="176" xlinkHref="#a" />
    <use x="160" y="192" xlinkHref="#a" />
    <use x="160" y="200" xlinkHref="#a" />
    <use x="160" y="216" xlinkHref="#a" />
    <use x="160" y="240" xlinkHref="#a" />
    <use x="160" y="264" xlinkHref="#a" />
    <use x="160" y="280" xlinkHref="#a" />
    <use x="168" y="32" xlinkHref="#a" />
    <use x="168" y="56" xlinkHref="#a" />
    <use x="168" y="96" xlinkHref="#a" />
    <use x="168" y="104" xlinkHref="#a" />
    <use x="168" y="128" xlinkHref="#a" />
    <use x="168" y="144" xlinkHref="#a" />
    <use x="168" y="160" xlinkHref="#a" />
    <use x="168" y="192" xlinkHref="#a" />
    <use x="168" y="200" xlinkHref="#a" />
    <use x="168" y="208" xlinkHref="#a" />
    <use x="168" y="224" xlinkHref="#a" />
    <use x="168" y="256" xlinkHref="#a" />
    <use x="168" y="288" xlinkHref="#a" />
    <use x="176" y="32" xlinkHref="#a" />
    <use x="176" y="40" xlinkHref="#a" />
    <use x="176" y="64" xlinkHref="#a" />
    <use x="176" y="80" xlinkHref="#a" />
    <use x="176" y="88" xlinkHref="#a" />
    <use x="176" y="96" xlinkHref="#a" />
    <use x="176" y="144" xlinkHref="#a" />
    <use x="176" y="152" xlinkHref="#a" />
    <use x="176" y="192" xlinkHref="#a" />
    <use x="176" y="200" xlinkHref="#a" />
    <use x="176" y="216" xlinkHref="#a" />
    <use x="176" y="232" xlinkHref="#a" />
    <use x="176" y="240" xlinkHref="#a" />
    <use x="184" y="32" xlinkHref="#a" />
    <use x="184" y="48" xlinkHref="#a" />
    <use x="184" y="56" xlinkHref="#a" />
    <use x="184" y="64" xlinkHref="#a" />
    <use x="184" y="88" xlinkHref="#a" />
    <use x="184" y="104" xlinkHref="#a" />
    <use x="184" y="128" xlinkHref="#a" />
    <use x="184" y="136" xlinkHref="#a" />
    <use x="184" y="160" xlinkHref="#a" />
    <use x="184" y="168" xlinkHref="#a" />
    <use x="184" y="200" xlinkHref="#a" />
    <use x="184" y="232" xlinkHref="#a" />
    <use x="184" y="264" xlinkHref="#a" />
    <use x="192" y="32" xlinkHref="#a" />
    <use x="192" y="40" xlinkHref="#a" />
    <use x="192" y="48" xlinkHref="#a" />
    <use x="192" y="56" xlinkHref="#a" />
    <use x="192" y="72" xlinkHref="#a" />
    <use x="192" y="80" xlinkHref="#a" />
    <use x="192" y="96" xlinkHref="#a" />
    <use x="192" y="104" xlinkHref="#a" />
    <use x="192" y="120" xlinkHref="#a" />
    <use x="192" y="136" xlinkHref="#a" />
    <use x="192" y="144" xlinkHref="#a" />
    <use x="192" y="168" xlinkHref="#a" />
    <use x="192" y="176" xlinkHref="#a" />
    <use x="192" y="200" xlinkHref="#a" />
    <use x="192" y="216" xlinkHref="#a" />
    <use x="192" y="224" xlinkHref="#a" />
    <use x="192" y="232" xlinkHref="#a" />
    <use x="192" y="240" xlinkHref="#a" />
    <use x="192" y="248" xlinkHref="#a" />
    <use x="192" y="264" xlinkHref="#a" />
    <use x="192" y="280" xlinkHref="#a" />
    <use x="200" y="32" xlinkHref="#a" />
    <use x="200" y="48" xlinkHref="#a" />
    <use x="200" y="64" xlinkHref="#a" />
    <use x="200" y="72" xlinkHref="#a" />
    <use x="200" y="88" xlinkHref="#a" />
    <use x="200" y="120" xlinkHref="#a" />
    <use x="200" y="136" xlinkHref="#a" />
    <use x="200" y="144" xlinkHref="#a" />
    <use x="200" y="152" xlinkHref="#a" />
    <use x="200" y="168" xlinkHref="#a" />
    <use x="200" y="176" xlinkHref="#a" />
    <use x="200" y="184" xlinkHref="#a" />
    <use x="200" y="200" xlinkHref="#a" />
    <use x="200" y="208" xlinkHref="#a" />
    <use x="200" y="216" xlinkHref="#a" />
    <use x="200" y="232" xlinkHref="#a" />
    <use x="200" y="248" xlinkHref="#a" />
    <use x="200" y="280" xlinkHref="#a" />
    <use x="208" y="32" xlinkHref="#a" />
    <use x="208" y="56" xlinkHref="#a" />
    <use x="208" y="72" xlinkHref="#a" />
    <use x="208" y="80" xlinkHref="#a" />
    <use x="208" y="88" xlinkHref="#a" />
    <use x="208" y="112" xlinkHref="#a" />
    <use x="208" y="120" xlinkHref="#a" />
    <use x="208" y="128" xlinkHref="#a" />
    <use x="208" y="152" xlinkHref="#a" />
    <use x="208" y="160" xlinkHref="#a" />
    <use x="208" y="168" xlinkHref="#a" />
    <use x="208" y="192" xlinkHref="#a" />
    <use x="208" y="200" xlinkHref="#a" />
    <use x="208" y="224" xlinkHref="#a" />
    <use x="208" y="232" xlinkHref="#a" />
    <use x="208" y="248" xlinkHref="#a" />
    <use x="208" y="272" xlinkHref="#a" />
    <use x="208" y="280" xlinkHref="#a" />
    <use x="208" y="288" xlinkHref="#a" />
    <use x="216" y="32" xlinkHref="#a" />
    <use x="216" y="48" xlinkHref="#a" />
    <use x="216" y="56" xlinkHref="#a" />
    <use x="216" y="64" xlinkHref="#a" />
    <use x="216" y="104" xlinkHref="#a" />
    <use x="216" y="120" xlinkHref="#a" />
    <use x="216" y="136" xlinkHref="#a" />
    <use x="216" y="152" xlinkHref="#a" />
    <use x="216" y="160" xlinkHref="#a" />
    <use x="216" y="168" xlinkHref="#a" />
    <use x="216" y="192" xlinkHref="#a" />
    <use x="216" y="200" xlinkHref="#a" />
    <use x="216" y="216" xlinkHref="#a" />
    <use x="216" y="224" xlinkHref="#a" />
    <use x="216" y="240" xlinkHref="#a" />
    <use x="216" y="248" xlinkHref="#a" />
    <use x="216" y="264" xlinkHref="#a" />
    <use x="216" y="288" xlinkHref="#a" />
    <use x="224" y="56" xlinkHref="#a" />
    <use x="224" y="72" xlinkHref="#a" />
    <use x="224" y="80" xlinkHref="#a" />
    <use x="224" y="120" xlinkHref="#a" />
    <use x="224" y="160" xlinkHref="#a" />
    <use x="224" y="168" xlinkHref="#a" />
    <use x="224" y="192" xlinkHref="#a" />
    <use x="224" y="224" xlinkHref="#a" />
    <use x="224" y="232" xlinkHref="#a" />
    <use x="224" y="240" xlinkHref="#a" />
    <use x="224" y="248" xlinkHref="#a" />
    <use x="224" y="256" xlinkHref="#a" />
    <use x="224" y="272" xlinkHref="#a" />
    <use x="224" y="288" xlinkHref="#a" />
    <use x="232" y="96" xlinkHref="#a" />
    <use x="232" y="112" xlinkHref="#a" />
    <use x="232" y="120" xlinkHref="#a" />
    <use x="232" y="128" xlinkHref="#a" />
    <use x="232" y="144" xlinkHref="#a" />
    <use x="232" y="152" xlinkHref="#a" />
    <use x="232" y="160" xlinkHref="#a" />
    <use x="232" y="176" xlinkHref="#a" />
    <use x="232" y="192" xlinkHref="#a" />
    <use x="232" y="208" xlinkHref="#a" />
    <use x="232" y="216" xlinkHref="#a" />
    <use x="232" y="224" xlinkHref="#a" />
    <use x="232" y="256" xlinkHref="#a" />
    <use x="232" y="280" xlinkHref="#a" />
    <use x="240" y="32" xlinkHref="#a" />
    <use x="240" y="40" xlinkHref="#a" />
    <use x="240" y="48" xlinkHref="#a" />
    <use x="240" y="56" xlinkHref="#a" />
    <use x="240" y="64" xlinkHref="#a" />
    <use x="240" y="72" xlinkHref="#a" />
    <use x="240" y="80" xlinkHref="#a" />
    <use x="240" y="104" xlinkHref="#a" />
    <use x="240" y="112" xlinkHref="#a" />
    <use x="240" y="120" xlinkHref="#a" />
    <use x="240" y="136" xlinkHref="#a" />
    <use x="240" y="152" xlinkHref="#a" />
    <use x="240" y="168" xlinkHref="#a" />
    <use x="240" y="176" xlinkHref="#a" />
    <use x="240" y="200" xlinkHref="#a" />
    <use x="240" y="224" xlinkHref="#a" />
    <use x="240" y="240" xlinkHref="#a" />
    <use x="240" y="256" xlinkHref="#a" />
    <use x="240" y="272" xlinkHref="#a" />
    <use x="240" y="280" xlinkHref="#a" />
    <use x="240" y="288" xlinkHref="#a" />
    <use x="248" y="32" xlinkHref="#a" />
    <use x="248" y="80" xlinkHref="#a" />
    <use x="248" y="96" xlinkHref="#a" />
    <use x="248" y="128" xlinkHref="#a" />
    <use x="248" y="160" xlinkHref="#a" />
    <use x="248" y="176" xlinkHref="#a" />
    <use x="248" y="192" xlinkHref="#a" />
    <use x="248" y="200" xlinkHref="#a" />
    <use x="248" y="216" xlinkHref="#a" />
    <use x="248" y="224" xlinkHref="#a" />
    <use x="248" y="256" xlinkHref="#a" />
    <use x="248" y="288" xlinkHref="#a" />
    <use x="256" y="32" xlinkHref="#a" />
    <use x="256" y="48" xlinkHref="#a" />
    <use x="256" y="56" xlinkHref="#a" />
    <use x="256" y="64" xlinkHref="#a" />
    <use x="256" y="80" xlinkHref="#a" />
    <use x="256" y="112" xlinkHref="#a" />
    <use x="256" y="120" xlinkHref="#a" />
    <use x="256" y="128" xlinkHref="#a" />
    <use x="256" y="144" xlinkHref="#a" />
    <use x="256" y="160" xlinkHref="#a" />
    <use x="256" y="176" xlinkHref="#a" />
    <use x="256" y="184" xlinkHref="#a" />
    <use x="256" y="192" xlinkHref="#a" />
    <use x="256" y="216" xlinkHref="#a" />
    <use x="256" y="224" xlinkHref="#a" />
    <use x="256" y="232" xlinkHref="#a" />
    <use x="256" y="240" xlinkHref="#a" />
    <use x="256" y="248" xlinkHref="#a" />
    <use x="256" y="256" xlinkHref="#a" />
    <use x="256" y="264" xlinkHref="#a" />
    <use x="264" y="32" xlinkHref="#a" />
    <use x="264" y="48" xlinkHref="#a" />
    <use x="264" y="56" xlinkHref="#a" />
    <use x="264" y="64" xlinkHref="#a" />
    <use x="264" y="80" xlinkHref="#a" />
    <use x="264" y="96" xlinkHref="#a" />
    <use x="264" y="112" xlinkHref="#a" />
    <use x="264" y="128" xlinkHref="#a" />
    <use x="264" y="168" xlinkHref="#a" />
    <use x="264" y="176" xlinkHref="#a" />
    <use x="264" y="200" xlinkHref="#a" />
    <use x="264" y="208" xlinkHref="#a" />
    <use x="264" y="216" xlinkHref="#a" />
    <use x="264" y="224" xlinkHref="#a" />
    <use x="264" y="248" xlinkHref="#a" />
    <use x="264" y="264" xlinkHref="#a" />
    <use x="264" y="280" xlinkHref="#a" />
    <use x="272" y="32" xlinkHref="#a" />
    <use x="272" y="48" xlinkHref="#a" />
    <use x="272" y="56" xlinkHref="#a" />
    <use x="272" y="64" xlinkHref="#a" />
    <use x="272" y="80" xlinkHref="#a" />
    <use x="272" y="104" xlinkHref="#a" />
    <use x="272" y="120" xlinkHref="#a" />
    <use x="272" y="152" xlinkHref="#a" />
    <use x="272" y="184" xlinkHref="#a" />
    <use x="272" y="216" xlinkHref="#a" />
    <use x="272" y="232" xlinkHref="#a" />
    <use x="272" y="240" xlinkHref="#a" />
    <use x="272" y="248" xlinkHref="#a" />
    <use x="272" y="272" xlinkHref="#a" />
    <use x="272" y="280" xlinkHref="#a" />
    <use x="280" y="32" xlinkHref="#a" />
    <use x="280" y="80" xlinkHref="#a" />
    <use x="280" y="96" xlinkHref="#a" />
    <use x="280" y="104" xlinkHref="#a" />
    <use x="280" y="112" xlinkHref="#a" />
    <use x="280" y="136" xlinkHref="#a" />
    <use x="280" y="144" xlinkHref="#a" />
    <use x="280" y="160" xlinkHref="#a" />
    <use x="280" y="168" xlinkHref="#a" />
    <use x="280" y="176" xlinkHref="#a" />
    <use x="280" y="192" xlinkHref="#a" />
    <use x="280" y="200" xlinkHref="#a" />
    <use x="280" y="208" xlinkHref="#a" />
    <use x="280" y="240" xlinkHref="#a" />
    <use x="280" y="248" xlinkHref="#a" />
    <use x="280" y="272" xlinkHref="#a" />
    <use x="280" y="288" xlinkHref="#a" />
    <use x="288" y="32" xlinkHref="#a" />
    <use x="288" y="40" xlinkHref="#a" />
    <use x="288" y="48" xlinkHref="#a" />
    <use x="288" y="56" xlinkHref="#a" />
    <use x="288" y="64" xlinkHref="#a" />
    <use x="288" y="72" xlinkHref="#a" />
    <use x="288" y="80" xlinkHref="#a" />
    <use x="288" y="104" xlinkHref="#a" />
    <use x="288" y="136" xlinkHref="#a" />
    <use x="288" y="168" xlinkHref="#a" />
    <use x="288" y="200" xlinkHref="#a" />
    <use x="288" y="224" xlinkHref="#a" />
    <use x="288" y="232" xlinkHref="#a" />
    <use x="288" y="248" xlinkHref="#a" />
  </svg>
);
