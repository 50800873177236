import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { media } from 'Src/app/Styles/Theme';
import Description from './Description';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: none;
  overflow: hidden;
  
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 440px;
    display: block;
    background-color: #f4f9fa;
    background-image: none;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    ${media.tablet`
      height: 440px;
      right: -340px;
      background-image: url(${props => props.bgSrc});
    `}
    ${media.laptop`

      right: -150px;
    `}
    ${media.widescreen`
      height: 488px;
      right: -185px;
    `}
    ${media.ultrawidescreen`
      height: 608px;
      right: -85px;
    `}
  }
  ${media.tablet`
    height: 440px;
    background: linear-gradient(to right, #f4f9fa, #f4f9fa 50%, transparent 50%);
  `}
  ${media.laptop`
    height: 440px;
  `}
  ${media.widescreen`
    height: 488px;
  `}
  ${media.ultrawidescreen`
    height: 608px;
  `}
`;

const WrapperContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  ${media.tablet`
    flex-direction: column;
  `};
  ${media.laptop`
    width: 944px;
  `};
  ${media.desktop`
    width: 1224px;
  `};
  ${media.widescreen`
    width: 1384px;
  `};
  ${media.ultrawidescreen`
    width: 1416px;
  `};
`;

const MobileBanner = styled.div`
  width: 100%;
  height: 230px;
  display: block;
  background-image: url(${props => props.bgSrc});
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: cover;
  ${media.tablet`
    display: none;
  `};
`;

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          Background: file(
            relativeDirectory: { regex: "Header/" }
            base: { eq: "topBgNew1.png" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <Wrapper bgSrc={data.Background.publicURL}>
            <WrapperContent>
              <Description />
              <MobileBanner bgSrc={data.Background.publicURL} />
            </WrapperContent>
          </Wrapper>
        );
      }}
    />
  );
};
export default Header;
