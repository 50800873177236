import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

const Sticker = styled.div`
  width: 273px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  border: 1px solid #335f7d;
  box-sizing: border-box;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet`
    &:not(:last-child) {
      margin-right: 20px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
  `}
  &:nth-child(3) {
    ${media.laptop`
      width: 253px;
    `}
    ${media.widescreen`
      width: 284px;
    `}
  }
  ${media.widescreen`
    width: 336px;
    font-size: 24px;
    padding : 56px 52px;
  `}
`;

const StickerDescription = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.secondary_blue_light};
  ${media.widescreen`
    font-size: 16px;
  `}
`;

const StickerImage = styled.img`
  width: 232px;
`;

const Item = ({ text, alt, iconSrc }) => {
  return (
    <Sticker>
      <StickerDescription>{text}</StickerDescription>
      <StickerImage src={iconSrc} alt={alt} />
    </Sticker>
  );
};
export default Item;

Item.propTypes = {
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
};
