import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import Item from './Item';

const Row = styled.div`
  background: ${props => props.theme.primary_petrol};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 40px 16px;
  background: ${props => props.theme.primary_petrol};
  ${media.laptop`
    padding: 64px 104px;
  `};
  ${media.desktop`
    width: 1240px;
  `};
  ${media.widescreen`
    width: 1440px;
    padding: 80px 104px;
  `};
  ${media.ultrawidescreen`
    padding: 136px 104px;
  `}
`;

const Title = styled.h2`
  width: 100%;
  margin: 0;
  margin-bottom: 16px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  letter-spacing: 0.02em;
  color: ${props => props.theme.white};
  ${media.laptop`
    margin-bottom: 56px;
    font-size: 32px;
    letter-spacing: 0.01em;
  `}
  ${media.widescreen`
    margin-bottom: 24px;
  `}
`;

const Description = styled.p`
  width: 100%;
  font-family: ${props => props.theme.fonts.font};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.secondary_petrol};
  text-align: center;
  margin: 0 auto 35px;
  ${media.phablet`
    width: 445px;
  `}
  ${media.desktop`
    width: 637px;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 50px;
  `}
  ${media.widescreen`
    width: 637px;
    font-size: 18px;
    line-height: 28px;
    margin: 0 auto 50px;
  `}
  ${media.ultrawidescreen`
    width: 928px;
    font-size: 20px;
    line-height: 35px;
  `}
`;

const Stickers = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.tablet`
    flex-direction: row;
  `}
`;

const Sticker = () => {
  const {
    state: { lang },
  } = useContext(MainContext);

  const { title, description, sticker1, sticker2 } = Locales[lang].Sticker;
  return (
    <StaticQuery
      query={graphql`
        query {
          GoodSticker: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "good_sticker.png" }
          ) {
            publicURL
          }
          BadSticker: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "bad_sticker2.png" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <Row>
            <Wrapper>
              <Title>{title}</Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <Stickers>
                <Item
                  key="good-sticker"
                  text={sticker1}
                  iconSrc={data.GoodSticker.publicURL}
                  alt="good-sticker"
                  isIconCheck
                />
                <Item
                  key="bad-sticker"
                  text={sticker2}
                  iconSrc={data.BadSticker.publicURL}
                  alt="bad-sticker"
                  isIconCheck={false}
                />
              </Stickers>
            </Wrapper>
          </Row>
        );
      }}
    />
  );
};

export default Sticker;
