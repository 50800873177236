import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookie from 'js-cookie';
import { media } from 'Src/app/Styles/Theme';
import Header from 'Components/TG/TG01__Header';
import Logos from 'Components/TG/TG02__Logos';
import Title from 'Components/TG/TG03__Title';
import Instruction from 'Components/TG/TG07__Instruction';
import Form from 'Components/TG/TG04__Form';
import Sticker from 'Components/TG/TG05__Sticker';
import Media from 'Components/TG/TG08__Media';
import Dealers from 'Components/TG/TG09__Dealers';
import Contacts from 'Components/TG/TG10__Contacts';
import Footer from 'Components/TG/TG11__Footer';
import Marking from 'Components/TG/TG06__Marking';
import MainContext from 'Src/app/MainContextProvider';
import VideoPlayer from 'Common/VideoPlayer';
import TermsPopup from 'Common/TermsPopup';

const LogosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.tablet`
    display: none;
  `};
`;

const Main = () => {
  const {
    state: { playVideo, showTerms },
    dispatch,
  } = useContext(MainContext);

  const [code, setCode] = useState('');

  useEffect(() => {
    if (!Cookie.get('kdx_fv')) Cookie.set('kdx_fv', 'visited');

    dispatch({ type: 'page', payload: 'main' });

    if (Cookie.get('kdx_agree')) {
      dispatch({ type: 'showConsent', payload: false });
    } else {
      dispatch({ type: 'showConsent', payload: true });
    }
  }, []);

  const scrollIntoCodeEnter = useCallback(() => {
    const codeEnterEl = document.getElementById('unique-code-wrapper');
    if (codeEnterEl) {
      codeEnterEl.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search.slice(1)).get('code');
    if (code) {
      setCode(code);
      scrollIntoCodeEnter();
    }
  }, []);

  return (
    <>
      <Header page="main" />
      <LogosWrapper>
        <Logos />
      </LogosWrapper>
      <Title />
      <Form code={code} />
      <Sticker />
      <Marking />
      <Instruction />
      <Media />
      <Dealers />
      <Contacts />
      <Footer />
      {playVideo && (
        <VideoPlayer
          url={playVideo}
          close={() => dispatch({ type: 'playVideo', payload: null })}
        />
      )}
      {showTerms && (
        <TermsPopup
          closePopup={() => dispatch({ type: 'showTerms', payload: false })}
        />
      )}
    </>
  );
};

export default Main;
