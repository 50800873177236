import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MainContext from 'Src/app/MainContextProvider';
import { media } from 'Src/app/Styles/Theme';

const Product = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 10px;
  ${media.tablet`
    width: 45%;
  `}
  ${media.ultrawidescreen`
    width: 33%;
  `}
`;

const ProductDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin: 0;
  padding-left: 15px;
  color: ${props => props.theme.primary_petrol};
  cursor: ${props => props.isVideoLink && 'pointer'};
  &:hover {
    color: ${props => props.isVideoLink && props.theme.secondary_blue_dark};
    span {
      border-color: ${props =>
        props.isVideoLink && props.theme.secondary_blue_dark};
    }
  }
  span {
    border-bottom: 1px solid
      ${props =>
        props.isVideoLink ? props.theme.secondary_blue_light : 'transparent'};
  }
`;

const ProductImage = styled.img`
  width: 40px;
`;

const Item = ({ text, alt, iconSrc, video, name }) => {
  const { dispatch } = useContext(MainContext);
  return (
    <Product>
      <ProductImage src={iconSrc} alt={alt} />
      <ProductDescription
        onClick={() => {
          if (video) {
            dispatch({ type: 'playVideo', payload: video });

            if (name) {
              window.dataLayer_kdx = window.dataLayer_kdx || [];
              window.dataLayer_kdx.push({
                event: 'send_ga',
                eventCategory: 'Video',
                eventAction: 'Play',
                eventLabel: name,
              });
            }
          }
        }}
        isVideoLink={!!video}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </ProductDescription>
    </Product>
  );
};
export default Item;

Item.propTypes = {
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
};
