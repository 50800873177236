import React from 'react';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

const Product = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  padding-right: 16px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props =>
    props.active ? props.theme.white : props.theme.secondary_petrol};
  white-space: nowrap;
  ${media.laptop`
    font-size: 20px;
    line-height: 35px;
    padding: 20px 0;
    border-bottom: 0;
  `}
  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    display: none;
    width: 34px;
    height: 2px;
    margin-right: 12px;
    background: ${props =>
      props.active ? props.theme.white : props.theme.secondary_petrol};
    ${media.laptop`
      display: ${props => (props.active ? 'block' : 'none')};
    `}
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 2px;
    background: ${props => (props.active ? '#ffffff' : 'transparent')};
    ${media.laptop`
      background: none;
    `}
  }
`;

const ProductTab = ({ index, title, currentIndex, setCurrentIndex }) => {
  return (
    <Product
      active={currentIndex === index}
      onClick={() => setCurrentIndex(index)}
    >
      {title}
    </Product>
  );
};

export default ProductTab;
