import React from 'react';

export default () => (
  <svg
    width="328"
    height="328"
    viewBox="0 0 328 328"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnsEv="http://www.w3.org/2001/xml-events"
  >
    <rect x="0" y="0" width="328" height="328" fill="#ffffff" />
    <defs>
      <rect id="p" width="8" height="8" />
    </defs>
    <g fill="#000000">
      <use xlinkHref="#p" x="32" y="32" />
      <use xlinkHref="#p" x="32" y="40" />
      <use xlinkHref="#p" x="32" y="48" />
      <use xlinkHref="#p" x="32" y="56" />
      <use xlinkHref="#p" x="32" y="64" />
      <use xlinkHref="#p" x="32" y="72" />
      <use xlinkHref="#p" x="32" y="80" />
      <use xlinkHref="#p" x="32" y="96" />
      <use xlinkHref="#p" x="32" y="120" />
      <use xlinkHref="#p" x="32" y="128" />
      <use xlinkHref="#p" x="32" y="136" />
      <use xlinkHref="#p" x="32" y="160" />
      <use xlinkHref="#p" x="32" y="176" />
      <use xlinkHref="#p" x="32" y="192" />
      <use xlinkHref="#p" x="32" y="200" />
      <use xlinkHref="#p" x="32" y="216" />
      <use xlinkHref="#p" x="32" y="240" />
      <use xlinkHref="#p" x="32" y="248" />
      <use xlinkHref="#p" x="32" y="256" />
      <use xlinkHref="#p" x="32" y="264" />
      <use xlinkHref="#p" x="32" y="272" />
      <use xlinkHref="#p" x="32" y="280" />
      <use xlinkHref="#p" x="32" y="288" />
      <use xlinkHref="#p" x="40" y="32" />
      <use xlinkHref="#p" x="40" y="80" />
      <use xlinkHref="#p" x="40" y="96" />
      <use xlinkHref="#p" x="40" y="104" />
      <use xlinkHref="#p" x="40" y="112" />
      <use xlinkHref="#p" x="40" y="120" />
      <use xlinkHref="#p" x="40" y="128" />
      <use xlinkHref="#p" x="40" y="144" />
      <use xlinkHref="#p" x="40" y="152" />
      <use xlinkHref="#p" x="40" y="176" />
      <use xlinkHref="#p" x="40" y="192" />
      <use xlinkHref="#p" x="40" y="200" />
      <use xlinkHref="#p" x="40" y="224" />
      <use xlinkHref="#p" x="40" y="240" />
      <use xlinkHref="#p" x="40" y="288" />
      <use xlinkHref="#p" x="48" y="32" />
      <use xlinkHref="#p" x="48" y="48" />
      <use xlinkHref="#p" x="48" y="56" />
      <use xlinkHref="#p" x="48" y="64" />
      <use xlinkHref="#p" x="48" y="80" />
      <use xlinkHref="#p" x="48" y="112" />
      <use xlinkHref="#p" x="48" y="120" />
      <use xlinkHref="#p" x="48" y="136" />
      <use xlinkHref="#p" x="48" y="168" />
      <use xlinkHref="#p" x="48" y="184" />
      <use xlinkHref="#p" x="48" y="216" />
      <use xlinkHref="#p" x="48" y="224" />
      <use xlinkHref="#p" x="48" y="240" />
      <use xlinkHref="#p" x="48" y="256" />
      <use xlinkHref="#p" x="48" y="264" />
      <use xlinkHref="#p" x="48" y="272" />
      <use xlinkHref="#p" x="48" y="288" />
      <use xlinkHref="#p" x="56" y="32" />
      <use xlinkHref="#p" x="56" y="48" />
      <use xlinkHref="#p" x="56" y="56" />
      <use xlinkHref="#p" x="56" y="64" />
      <use xlinkHref="#p" x="56" y="80" />
      <use xlinkHref="#p" x="56" y="96" />
      <use xlinkHref="#p" x="56" y="104" />
      <use xlinkHref="#p" x="56" y="128" />
      <use xlinkHref="#p" x="56" y="136" />
      <use xlinkHref="#p" x="56" y="144" />
      <use xlinkHref="#p" x="56" y="152" />
      <use xlinkHref="#p" x="56" y="168" />
      <use xlinkHref="#p" x="56" y="184" />
      <use xlinkHref="#p" x="56" y="240" />
      <use xlinkHref="#p" x="56" y="256" />
      <use xlinkHref="#p" x="56" y="264" />
      <use xlinkHref="#p" x="56" y="272" />
      <use xlinkHref="#p" x="56" y="288" />
      <use xlinkHref="#p" x="64" y="32" />
      <use xlinkHref="#p" x="64" y="48" />
      <use xlinkHref="#p" x="64" y="56" />
      <use xlinkHref="#p" x="64" y="64" />
      <use xlinkHref="#p" x="64" y="80" />
      <use xlinkHref="#p" x="64" y="104" />
      <use xlinkHref="#p" x="64" y="112" />
      <use xlinkHref="#p" x="64" y="160" />
      <use xlinkHref="#p" x="64" y="168" />
      <use xlinkHref="#p" x="64" y="176" />
      <use xlinkHref="#p" x="64" y="184" />
      <use xlinkHref="#p" x="64" y="192" />
      <use xlinkHref="#p" x="64" y="200" />
      <use xlinkHref="#p" x="64" y="216" />
      <use xlinkHref="#p" x="64" y="240" />
      <use xlinkHref="#p" x="64" y="256" />
      <use xlinkHref="#p" x="64" y="264" />
      <use xlinkHref="#p" x="64" y="272" />
      <use xlinkHref="#p" x="64" y="288" />
      <use xlinkHref="#p" x="72" y="32" />
      <use xlinkHref="#p" x="72" y="80" />
      <use xlinkHref="#p" x="72" y="104" />
      <use xlinkHref="#p" x="72" y="120" />
      <use xlinkHref="#p" x="72" y="144" />
      <use xlinkHref="#p" x="72" y="160" />
      <use xlinkHref="#p" x="72" y="176" />
      <use xlinkHref="#p" x="72" y="192" />
      <use xlinkHref="#p" x="72" y="216" />
      <use xlinkHref="#p" x="72" y="240" />
      <use xlinkHref="#p" x="72" y="288" />
      <use xlinkHref="#p" x="80" y="32" />
      <use xlinkHref="#p" x="80" y="40" />
      <use xlinkHref="#p" x="80" y="48" />
      <use xlinkHref="#p" x="80" y="56" />
      <use xlinkHref="#p" x="80" y="64" />
      <use xlinkHref="#p" x="80" y="72" />
      <use xlinkHref="#p" x="80" y="80" />
      <use xlinkHref="#p" x="80" y="96" />
      <use xlinkHref="#p" x="80" y="112" />
      <use xlinkHref="#p" x="80" y="128" />
      <use xlinkHref="#p" x="80" y="144" />
      <use xlinkHref="#p" x="80" y="160" />
      <use xlinkHref="#p" x="80" y="176" />
      <use xlinkHref="#p" x="80" y="192" />
      <use xlinkHref="#p" x="80" y="208" />
      <use xlinkHref="#p" x="80" y="224" />
      <use xlinkHref="#p" x="80" y="240" />
      <use xlinkHref="#p" x="80" y="248" />
      <use xlinkHref="#p" x="80" y="256" />
      <use xlinkHref="#p" x="80" y="264" />
      <use xlinkHref="#p" x="80" y="272" />
      <use xlinkHref="#p" x="80" y="280" />
      <use xlinkHref="#p" x="80" y="288" />
      <use xlinkHref="#p" x="88" y="96" />
      <use xlinkHref="#p" x="88" y="104" />
      <use xlinkHref="#p" x="88" y="112" />
      <use xlinkHref="#p" x="88" y="136" />
      <use xlinkHref="#p" x="88" y="184" />
      <use xlinkHref="#p" x="88" y="192" />
      <use xlinkHref="#p" x="88" y="200" />
      <use xlinkHref="#p" x="88" y="208" />
      <use xlinkHref="#p" x="88" y="224" />
      <use xlinkHref="#p" x="96" y="40" />
      <use xlinkHref="#p" x="96" y="48" />
      <use xlinkHref="#p" x="96" y="64" />
      <use xlinkHref="#p" x="96" y="72" />
      <use xlinkHref="#p" x="96" y="80" />
      <use xlinkHref="#p" x="96" y="88" />
      <use xlinkHref="#p" x="96" y="104" />
      <use xlinkHref="#p" x="96" y="112" />
      <use xlinkHref="#p" x="96" y="128" />
      <use xlinkHref="#p" x="96" y="144" />
      <use xlinkHref="#p" x="96" y="160" />
      <use xlinkHref="#p" x="96" y="208" />
      <use xlinkHref="#p" x="96" y="232" />
      <use xlinkHref="#p" x="96" y="240" />
      <use xlinkHref="#p" x="96" y="264" />
      <use xlinkHref="#p" x="96" y="280" />
      <use xlinkHref="#p" x="96" y="288" />
      <use xlinkHref="#p" x="104" y="40" />
      <use xlinkHref="#p" x="104" y="56" />
      <use xlinkHref="#p" x="104" y="64" />
      <use xlinkHref="#p" x="104" y="104" />
      <use xlinkHref="#p" x="104" y="120" />
      <use xlinkHref="#p" x="104" y="136" />
      <use xlinkHref="#p" x="104" y="152" />
      <use xlinkHref="#p" x="104" y="160" />
      <use xlinkHref="#p" x="104" y="176" />
      <use xlinkHref="#p" x="104" y="200" />
      <use xlinkHref="#p" x="104" y="208" />
      <use xlinkHref="#p" x="104" y="224" />
      <use xlinkHref="#p" x="104" y="240" />
      <use xlinkHref="#p" x="104" y="256" />
      <use xlinkHref="#p" x="104" y="264" />
      <use xlinkHref="#p" x="112" y="32" />
      <use xlinkHref="#p" x="112" y="40" />
      <use xlinkHref="#p" x="112" y="64" />
      <use xlinkHref="#p" x="112" y="72" />
      <use xlinkHref="#p" x="112" y="80" />
      <use xlinkHref="#p" x="112" y="88" />
      <use xlinkHref="#p" x="112" y="120" />
      <use xlinkHref="#p" x="112" y="128" />
      <use xlinkHref="#p" x="112" y="144" />
      <use xlinkHref="#p" x="112" y="152" />
      <use xlinkHref="#p" x="112" y="184" />
      <use xlinkHref="#p" x="112" y="192" />
      <use xlinkHref="#p" x="112" y="200" />
      <use xlinkHref="#p" x="112" y="208" />
      <use xlinkHref="#p" x="112" y="216" />
      <use xlinkHref="#p" x="112" y="256" />
      <use xlinkHref="#p" x="112" y="264" />
      <use xlinkHref="#p" x="112" y="272" />
      <use xlinkHref="#p" x="120" y="88" />
      <use xlinkHref="#p" x="120" y="96" />
      <use xlinkHref="#p" x="120" y="104" />
      <use xlinkHref="#p" x="120" y="112" />
      <use xlinkHref="#p" x="120" y="120" />
      <use xlinkHref="#p" x="120" y="128" />
      <use xlinkHref="#p" x="120" y="136" />
      <use xlinkHref="#p" x="120" y="144" />
      <use xlinkHref="#p" x="120" y="152" />
      <use xlinkHref="#p" x="120" y="176" />
      <use xlinkHref="#p" x="120" y="200" />
      <use xlinkHref="#p" x="120" y="232" />
      <use xlinkHref="#p" x="120" y="256" />
      <use xlinkHref="#p" x="120" y="280" />
      <use xlinkHref="#p" x="120" y="288" />
      <use xlinkHref="#p" x="128" y="56" />
      <use xlinkHref="#p" x="128" y="64" />
      <use xlinkHref="#p" x="128" y="72" />
      <use xlinkHref="#p" x="128" y="80" />
      <use xlinkHref="#p" x="128" y="96" />
      <use xlinkHref="#p" x="128" y="104" />
      <use xlinkHref="#p" x="128" y="112" />
      <use xlinkHref="#p" x="128" y="120" />
      <use xlinkHref="#p" x="128" y="160" />
      <use xlinkHref="#p" x="128" y="168" />
      <use xlinkHref="#p" x="128" y="176" />
      <use xlinkHref="#p" x="128" y="184" />
      <use xlinkHref="#p" x="128" y="224" />
      <use xlinkHref="#p" x="128" y="232" />
      <use xlinkHref="#p" x="128" y="240" />
      <use xlinkHref="#p" x="128" y="248" />
      <use xlinkHref="#p" x="128" y="288" />
      <use xlinkHref="#p" x="136" y="32" />
      <use xlinkHref="#p" x="136" y="64" />
      <use xlinkHref="#p" x="136" y="96" />
      <use xlinkHref="#p" x="136" y="104" />
      <use xlinkHref="#p" x="136" y="112" />
      <use xlinkHref="#p" x="136" y="120" />
      <use xlinkHref="#p" x="136" y="136" />
      <use xlinkHref="#p" x="136" y="144" />
      <use xlinkHref="#p" x="136" y="160" />
      <use xlinkHref="#p" x="136" y="168" />
      <use xlinkHref="#p" x="136" y="248" />
      <use xlinkHref="#p" x="136" y="256" />
      <use xlinkHref="#p" x="136" y="272" />
      <use xlinkHref="#p" x="136" y="280" />
      <use xlinkHref="#p" x="144" y="32" />
      <use xlinkHref="#p" x="144" y="48" />
      <use xlinkHref="#p" x="144" y="56" />
      <use xlinkHref="#p" x="144" y="64" />
      <use xlinkHref="#p" x="144" y="72" />
      <use xlinkHref="#p" x="144" y="80" />
      <use xlinkHref="#p" x="144" y="88" />
      <use xlinkHref="#p" x="144" y="96" />
      <use xlinkHref="#p" x="144" y="128" />
      <use xlinkHref="#p" x="144" y="144" />
      <use xlinkHref="#p" x="144" y="168" />
      <use xlinkHref="#p" x="144" y="176" />
      <use xlinkHref="#p" x="144" y="184" />
      <use xlinkHref="#p" x="144" y="200" />
      <use xlinkHref="#p" x="144" y="216" />
      <use xlinkHref="#p" x="144" y="248" />
      <use xlinkHref="#p" x="144" y="256" />
      <use xlinkHref="#p" x="144" y="288" />
      <use xlinkHref="#p" x="152" y="32" />
      <use xlinkHref="#p" x="152" y="40" />
      <use xlinkHref="#p" x="152" y="48" />
      <use xlinkHref="#p" x="152" y="56" />
      <use xlinkHref="#p" x="152" y="64" />
      <use xlinkHref="#p" x="152" y="72" />
      <use xlinkHref="#p" x="152" y="120" />
      <use xlinkHref="#p" x="152" y="192" />
      <use xlinkHref="#p" x="152" y="216" />
      <use xlinkHref="#p" x="152" y="248" />
      <use xlinkHref="#p" x="152" y="288" />
      <use xlinkHref="#p" x="160" y="40" />
      <use xlinkHref="#p" x="160" y="56" />
      <use xlinkHref="#p" x="160" y="64" />
      <use xlinkHref="#p" x="160" y="80" />
      <use xlinkHref="#p" x="160" y="88" />
      <use xlinkHref="#p" x="160" y="104" />
      <use xlinkHref="#p" x="160" y="112" />
      <use xlinkHref="#p" x="160" y="120" />
      <use xlinkHref="#p" x="160" y="128" />
      <use xlinkHref="#p" x="160" y="160" />
      <use xlinkHref="#p" x="160" y="168" />
      <use xlinkHref="#p" x="160" y="176" />
      <use xlinkHref="#p" x="160" y="192" />
      <use xlinkHref="#p" x="160" y="208" />
      <use xlinkHref="#p" x="160" y="224" />
      <use xlinkHref="#p" x="160" y="240" />
      <use xlinkHref="#p" x="160" y="248" />
      <use xlinkHref="#p" x="160" y="264" />
      <use xlinkHref="#p" x="160" y="288" />
      <use xlinkHref="#p" x="168" y="32" />
      <use xlinkHref="#p" x="168" y="40" />
      <use xlinkHref="#p" x="168" y="48" />
      <use xlinkHref="#p" x="168" y="88" />
      <use xlinkHref="#p" x="168" y="128" />
      <use xlinkHref="#p" x="168" y="136" />
      <use xlinkHref="#p" x="168" y="152" />
      <use xlinkHref="#p" x="168" y="160" />
      <use xlinkHref="#p" x="168" y="184" />
      <use xlinkHref="#p" x="168" y="208" />
      <use xlinkHref="#p" x="168" y="224" />
      <use xlinkHref="#p" x="168" y="232" />
      <use xlinkHref="#p" x="168" y="248" />
      <use xlinkHref="#p" x="168" y="256" />
      <use xlinkHref="#p" x="168" y="264" />
      <use xlinkHref="#p" x="168" y="272" />
      <use xlinkHref="#p" x="168" y="280" />
      <use xlinkHref="#p" x="176" y="32" />
      <use xlinkHref="#p" x="176" y="56" />
      <use xlinkHref="#p" x="176" y="64" />
      <use xlinkHref="#p" x="176" y="72" />
      <use xlinkHref="#p" x="176" y="80" />
      <use xlinkHref="#p" x="176" y="96" />
      <use xlinkHref="#p" x="176" y="104" />
      <use xlinkHref="#p" x="176" y="120" />
      <use xlinkHref="#p" x="176" y="136" />
      <use xlinkHref="#p" x="176" y="144" />
      <use xlinkHref="#p" x="176" y="168" />
      <use xlinkHref="#p" x="176" y="184" />
      <use xlinkHref="#p" x="176" y="192" />
      <use xlinkHref="#p" x="176" y="232" />
      <use xlinkHref="#p" x="176" y="240" />
      <use xlinkHref="#p" x="176" y="248" />
      <use xlinkHref="#p" x="176" y="272" />
      <use xlinkHref="#p" x="176" y="280" />
      <use xlinkHref="#p" x="184" y="32" />
      <use xlinkHref="#p" x="184" y="48" />
      <use xlinkHref="#p" x="184" y="72" />
      <use xlinkHref="#p" x="184" y="88" />
      <use xlinkHref="#p" x="184" y="112" />
      <use xlinkHref="#p" x="184" y="120" />
      <use xlinkHref="#p" x="184" y="128" />
      <use xlinkHref="#p" x="184" y="136" />
      <use xlinkHref="#p" x="184" y="152" />
      <use xlinkHref="#p" x="184" y="208" />
      <use xlinkHref="#p" x="184" y="216" />
      <use xlinkHref="#p" x="184" y="232" />
      <use xlinkHref="#p" x="184" y="248" />
      <use xlinkHref="#p" x="184" y="256" />
      <use xlinkHref="#p" x="192" y="40" />
      <use xlinkHref="#p" x="192" y="48" />
      <use xlinkHref="#p" x="192" y="56" />
      <use xlinkHref="#p" x="192" y="64" />
      <use xlinkHref="#p" x="192" y="80" />
      <use xlinkHref="#p" x="192" y="96" />
      <use xlinkHref="#p" x="192" y="104" />
      <use xlinkHref="#p" x="192" y="112" />
      <use xlinkHref="#p" x="192" y="128" />
      <use xlinkHref="#p" x="192" y="136" />
      <use xlinkHref="#p" x="192" y="144" />
      <use xlinkHref="#p" x="192" y="160" />
      <use xlinkHref="#p" x="192" y="200" />
      <use xlinkHref="#p" x="192" y="208" />
      <use xlinkHref="#p" x="192" y="232" />
      <use xlinkHref="#p" x="192" y="240" />
      <use xlinkHref="#p" x="192" y="248" />
      <use xlinkHref="#p" x="192" y="256" />
      <use xlinkHref="#p" x="192" y="272" />
      <use xlinkHref="#p" x="192" y="280" />
      <use xlinkHref="#p" x="200" y="72" />
      <use xlinkHref="#p" x="200" y="88" />
      <use xlinkHref="#p" x="200" y="96" />
      <use xlinkHref="#p" x="200" y="112" />
      <use xlinkHref="#p" x="200" y="120" />
      <use xlinkHref="#p" x="200" y="128" />
      <use xlinkHref="#p" x="200" y="136" />
      <use xlinkHref="#p" x="200" y="152" />
      <use xlinkHref="#p" x="200" y="160" />
      <use xlinkHref="#p" x="200" y="168" />
      <use xlinkHref="#p" x="200" y="184" />
      <use xlinkHref="#p" x="200" y="192" />
      <use xlinkHref="#p" x="200" y="200" />
      <use xlinkHref="#p" x="200" y="216" />
      <use xlinkHref="#p" x="200" y="224" />
      <use xlinkHref="#p" x="200" y="232" />
      <use xlinkHref="#p" x="200" y="240" />
      <use xlinkHref="#p" x="200" y="248" />
      <use xlinkHref="#p" x="200" y="256" />
      <use xlinkHref="#p" x="200" y="272" />
      <use xlinkHref="#p" x="200" y="280" />
      <use xlinkHref="#p" x="200" y="288" />
      <use xlinkHref="#p" x="208" y="40" />
      <use xlinkHref="#p" x="208" y="48" />
      <use xlinkHref="#p" x="208" y="56" />
      <use xlinkHref="#p" x="208" y="72" />
      <use xlinkHref="#p" x="208" y="80" />
      <use xlinkHref="#p" x="208" y="96" />
      <use xlinkHref="#p" x="208" y="112" />
      <use xlinkHref="#p" x="208" y="120" />
      <use xlinkHref="#p" x="208" y="136" />
      <use xlinkHref="#p" x="208" y="144" />
      <use xlinkHref="#p" x="208" y="152" />
      <use xlinkHref="#p" x="208" y="160" />
      <use xlinkHref="#p" x="208" y="168" />
      <use xlinkHref="#p" x="208" y="176" />
      <use xlinkHref="#p" x="208" y="184" />
      <use xlinkHref="#p" x="208" y="200" />
      <use xlinkHref="#p" x="208" y="240" />
      <use xlinkHref="#p" x="208" y="248" />
      <use xlinkHref="#p" x="216" y="32" />
      <use xlinkHref="#p" x="216" y="40" />
      <use xlinkHref="#p" x="216" y="64" />
      <use xlinkHref="#p" x="216" y="88" />
      <use xlinkHref="#p" x="216" y="96" />
      <use xlinkHref="#p" x="216" y="120" />
      <use xlinkHref="#p" x="216" y="144" />
      <use xlinkHref="#p" x="216" y="160" />
      <use xlinkHref="#p" x="216" y="168" />
      <use xlinkHref="#p" x="216" y="184" />
      <use xlinkHref="#p" x="216" y="216" />
      <use xlinkHref="#p" x="216" y="224" />
      <use xlinkHref="#p" x="216" y="232" />
      <use xlinkHref="#p" x="216" y="264" />
      <use xlinkHref="#p" x="216" y="280" />
      <use xlinkHref="#p" x="224" y="40" />
      <use xlinkHref="#p" x="224" y="80" />
      <use xlinkHref="#p" x="224" y="88" />
      <use xlinkHref="#p" x="224" y="104" />
      <use xlinkHref="#p" x="224" y="136" />
      <use xlinkHref="#p" x="224" y="152" />
      <use xlinkHref="#p" x="224" y="160" />
      <use xlinkHref="#p" x="224" y="184" />
      <use xlinkHref="#p" x="224" y="192" />
      <use xlinkHref="#p" x="224" y="200" />
      <use xlinkHref="#p" x="224" y="216" />
      <use xlinkHref="#p" x="224" y="224" />
      <use xlinkHref="#p" x="224" y="232" />
      <use xlinkHref="#p" x="224" y="240" />
      <use xlinkHref="#p" x="224" y="248" />
      <use xlinkHref="#p" x="224" y="256" />
      <use xlinkHref="#p" x="224" y="264" />
      <use xlinkHref="#p" x="224" y="272" />
      <use xlinkHref="#p" x="224" y="280" />
      <use xlinkHref="#p" x="224" y="288" />
      <use xlinkHref="#p" x="232" y="104" />
      <use xlinkHref="#p" x="232" y="128" />
      <use xlinkHref="#p" x="232" y="144" />
      <use xlinkHref="#p" x="232" y="168" />
      <use xlinkHref="#p" x="232" y="176" />
      <use xlinkHref="#p" x="232" y="192" />
      <use xlinkHref="#p" x="232" y="200" />
      <use xlinkHref="#p" x="232" y="224" />
      <use xlinkHref="#p" x="232" y="256" />
      <use xlinkHref="#p" x="232" y="264" />
      <use xlinkHref="#p" x="232" y="280" />
      <use xlinkHref="#p" x="240" y="32" />
      <use xlinkHref="#p" x="240" y="40" />
      <use xlinkHref="#p" x="240" y="48" />
      <use xlinkHref="#p" x="240" y="56" />
      <use xlinkHref="#p" x="240" y="64" />
      <use xlinkHref="#p" x="240" y="72" />
      <use xlinkHref="#p" x="240" y="80" />
      <use xlinkHref="#p" x="240" y="96" />
      <use xlinkHref="#p" x="240" y="104" />
      <use xlinkHref="#p" x="240" y="128" />
      <use xlinkHref="#p" x="240" y="136" />
      <use xlinkHref="#p" x="240" y="152" />
      <use xlinkHref="#p" x="240" y="160" />
      <use xlinkHref="#p" x="240" y="200" />
      <use xlinkHref="#p" x="240" y="208" />
      <use xlinkHref="#p" x="240" y="216" />
      <use xlinkHref="#p" x="240" y="224" />
      <use xlinkHref="#p" x="240" y="240" />
      <use xlinkHref="#p" x="240" y="256" />
      <use xlinkHref="#p" x="240" y="264" />
      <use xlinkHref="#p" x="240" y="280" />
      <use xlinkHref="#p" x="240" y="288" />
      <use xlinkHref="#p" x="248" y="32" />
      <use xlinkHref="#p" x="248" y="80" />
      <use xlinkHref="#p" x="248" y="96" />
      <use xlinkHref="#p" x="248" y="112" />
      <use xlinkHref="#p" x="248" y="144" />
      <use xlinkHref="#p" x="248" y="200" />
      <use xlinkHref="#p" x="248" y="208" />
      <use xlinkHref="#p" x="248" y="216" />
      <use xlinkHref="#p" x="248" y="224" />
      <use xlinkHref="#p" x="248" y="256" />
      <use xlinkHref="#p" x="248" y="272" />
      <use xlinkHref="#p" x="256" y="32" />
      <use xlinkHref="#p" x="256" y="48" />
      <use xlinkHref="#p" x="256" y="56" />
      <use xlinkHref="#p" x="256" y="64" />
      <use xlinkHref="#p" x="256" y="80" />
      <use xlinkHref="#p" x="256" y="96" />
      <use xlinkHref="#p" x="256" y="112" />
      <use xlinkHref="#p" x="256" y="120" />
      <use xlinkHref="#p" x="256" y="136" />
      <use xlinkHref="#p" x="256" y="160" />
      <use xlinkHref="#p" x="256" y="216" />
      <use xlinkHref="#p" x="256" y="224" />
      <use xlinkHref="#p" x="256" y="232" />
      <use xlinkHref="#p" x="256" y="240" />
      <use xlinkHref="#p" x="256" y="248" />
      <use xlinkHref="#p" x="256" y="256" />
      <use xlinkHref="#p" x="256" y="264" />
      <use xlinkHref="#p" x="256" y="272" />
      <use xlinkHref="#p" x="256" y="280" />
      <use xlinkHref="#p" x="256" y="288" />
      <use xlinkHref="#p" x="264" y="32" />
      <use xlinkHref="#p" x="264" y="48" />
      <use xlinkHref="#p" x="264" y="56" />
      <use xlinkHref="#p" x="264" y="64" />
      <use xlinkHref="#p" x="264" y="80" />
      <use xlinkHref="#p" x="264" y="104" />
      <use xlinkHref="#p" x="264" y="112" />
      <use xlinkHref="#p" x="264" y="128" />
      <use xlinkHref="#p" x="264" y="136" />
      <use xlinkHref="#p" x="264" y="144" />
      <use xlinkHref="#p" x="264" y="152" />
      <use xlinkHref="#p" x="264" y="168" />
      <use xlinkHref="#p" x="264" y="176" />
      <use xlinkHref="#p" x="264" y="184" />
      <use xlinkHref="#p" x="264" y="192" />
      <use xlinkHref="#p" x="264" y="208" />
      <use xlinkHref="#p" x="264" y="216" />
      <use xlinkHref="#p" x="264" y="224" />
      <use xlinkHref="#p" x="264" y="232" />
      <use xlinkHref="#p" x="264" y="240" />
      <use xlinkHref="#p" x="264" y="264" />
      <use xlinkHref="#p" x="264" y="288" />
      <use xlinkHref="#p" x="272" y="32" />
      <use xlinkHref="#p" x="272" y="48" />
      <use xlinkHref="#p" x="272" y="56" />
      <use xlinkHref="#p" x="272" y="64" />
      <use xlinkHref="#p" x="272" y="80" />
      <use xlinkHref="#p" x="272" y="96" />
      <use xlinkHref="#p" x="272" y="136" />
      <use xlinkHref="#p" x="272" y="168" />
      <use xlinkHref="#p" x="272" y="200" />
      <use xlinkHref="#p" x="272" y="240" />
      <use xlinkHref="#p" x="272" y="264" />
      <use xlinkHref="#p" x="272" y="272" />
      <use xlinkHref="#p" x="280" y="32" />
      <use xlinkHref="#p" x="280" y="80" />
      <use xlinkHref="#p" x="280" y="96" />
      <use xlinkHref="#p" x="280" y="120" />
      <use xlinkHref="#p" x="280" y="136" />
      <use xlinkHref="#p" x="280" y="144" />
      <use xlinkHref="#p" x="280" y="152" />
      <use xlinkHref="#p" x="280" y="176" />
      <use xlinkHref="#p" x="280" y="192" />
      <use xlinkHref="#p" x="280" y="216" />
      <use xlinkHref="#p" x="280" y="240" />
      <use xlinkHref="#p" x="280" y="248" />
      <use xlinkHref="#p" x="280" y="288" />
      <use xlinkHref="#p" x="288" y="32" />
      <use xlinkHref="#p" x="288" y="40" />
      <use xlinkHref="#p" x="288" y="48" />
      <use xlinkHref="#p" x="288" y="56" />
      <use xlinkHref="#p" x="288" y="64" />
      <use xlinkHref="#p" x="288" y="72" />
      <use xlinkHref="#p" x="288" y="80" />
      <use xlinkHref="#p" x="288" y="104" />
      <use xlinkHref="#p" x="288" y="112" />
      <use xlinkHref="#p" x="288" y="120" />
      <use xlinkHref="#p" x="288" y="128" />
      <use xlinkHref="#p" x="288" y="136" />
      <use xlinkHref="#p" x="288" y="160" />
      <use xlinkHref="#p" x="288" y="176" />
      <use xlinkHref="#p" x="288" y="200" />
      <use xlinkHref="#p" x="288" y="208" />
      <use xlinkHref="#p" x="288" y="216" />
      <use xlinkHref="#p" x="288" y="232" />
      <use xlinkHref="#p" x="288" y="248" />
      <use xlinkHref="#p" x="288" y="272" />
    </g>
  </svg>
);
