import React, { useContext, useState } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import IconClose from 'Src/assets/img/ico/icon_menu-close_invert.svg';
import IconQR from '../icons/qr_icon';
import AudiAsQr from '../icons/qr_audi_as';
import AudiGpQr from '../icons/qr_audi_gp';
import VwAsQr from '../icons/qr_vw_as';
import VwGpQr from '../icons/qr_vw_gp';
import SkodaAsQr from '../icons/qr_skoda_as';
import SkodaGpQr from '../icons/qr_skoda_gp';

const Title = styled.h3`
  display: flex;
  align-items: flex-end;
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  margin: 0;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  color: ${props =>
    props.invert ? props.theme.primary_petrol : props.theme.white};
  ${media.tablet`
    font-size: 22px;
    line-height: 36px;
    margin-left: ${props => (props.wideBlock ? '16px' : '0')};
  `}
  ${media.ultrawidescreen`
    font-size: 24px;
    line-height: 40px;
  `}
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  ${media.phablet`
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AppLink = styled.a`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: ${props =>
    props.invert
      ? props.theme.secondary_blue_dark
      : props.theme.secondary_blue_light};
  color: ${props => props.wideBlock && props.theme.secondary_petrol};
  cursor: pointer;
  width: auto;
  &:first-child {
    width: 100%;
    display: none;
  }
  &:hover {
    color: ${props =>
      props.invert ? props.theme.secondary_blue_light : props.theme.white};
    svg {
      fill: ${props =>
        props.invert ? props.theme.secondary_blue_light : props.theme.white};
    }
  }
  svg {
    vertical-align: bottom;
    fill: ${props =>
      props.invert
        ? props.theme.secondary_blue_dark
        : props.theme.secondary_blue_light};
    fill: ${props => props.wideBlock && props.theme.secondary_petrol};
  }
  ${media.tablet`
    &:first-child {
      display: block;
      width: ${props => props.wideBlock && 'auto'};
    }
    font-size: 16px;
  `}
  ${media.ultrawidescreen`
    font-size: 20px;
    line-height: 36px;
  `}
`;

const Arrow = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="#335F7D">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2132 12.9725L11.5787 15.7531L12.4161 16.5403L15.9686 12.7905C16.1679 12.5817 16.1783 12.2581 15.9974 12.0366L12.7248 8L11.8298 8.72173L14.3453 11.825L7.08025 11.825L7.08025 12.9725L14.2132 12.9725Z"
    />
  </svg>
);

const QRCodeWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  display: none;
  justify-content: space-around;
  padding: 40px;
  padding-left: ${props => (props.wideBlock ? '0' : '40px')};
  background: ${props =>
    props.invert ? props.theme.white : props.theme.primary_petrol};
  background: ${props => props.wideBlock && props.theme.secondary_blue_dark};
  ${media.tablet`
    display: flex;
    width: 370px;
  `}
  ${media.widescreen`
    width: 390px;
  `}
  ${media.ultrawidescreen`
    width: 454px;
  `}
`;

const Close = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  cursor: pointer;
`;

const BlockApp = styled.div`
  display: flex;
  flex-direction: ${props => (props.wideBlock ? 'row' : 'column')};
  align-items: center;
`;

const TitleQR = styled.p`
  margin-bottom: 0;
  margin-top: ${props => (props.wideBlock ? '0' : '8px')};
  margin-left: ${props => (props.wideBlock ? '8px' : '0')};
  text-align: center;
  color: ${props =>
    props.invert
      ? props.theme.secondary_blue_dark
      : props.theme.secondary_blue_light};
  color: ${props => props.wideBlock && props.theme.secondary_petrol};
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

const QrWrapper = styled.div`
  width: 130px;
  height: 130px;
  border: 1px solid;
  border-color: ${props =>
    props.invert ? props.theme.secondary_petrol : 'transparent'};
  ${media.tablet`
    width:  100px;
    height: 100px;
  `}
  svg {
    width: 100%;
    height: 100%;
  }
`;

const selectQrCode = (name, app) => {
  switch (name) {
    case 'vw':
      return app === 'as' ? <VwAsQr /> : <VwGpQr />;
    case 'audi':
      return app === 'as' ? <AudiAsQr /> : <AudiGpQr />;
    case 'skoda':
      return app === 'as' ? <SkodaAsQr /> : <SkodaGpQr />;
    default:
      break;
  }
};

const CardApp = ({ app, invert }) => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const [isShowQR, setShowQR] = useState(false);
  return (
    <>
      <TitleWrapper>
        <Title
          invert={invert}
          dangerouslySetInnerHTML={{
            __html: app.title,
          }}
        />
      </TitleWrapper>
      <LinkWrapper>
        <Links fullWidth>
          <AppLink as="div" invert={invert} onClick={() => setShowQR(true)}>
            {Locales[lang].Contacts.qr}
            <Arrow />
          </AppLink>
          <AppLink invert={invert} target="_blank" href={app.appstore}>
            App Store
            <Arrow />
          </AppLink>
          <AppLink invert={invert} target="_blank" href={app.googleplay}>
            Google Play
            <Arrow />
          </AppLink>
        </Links>
        <IconQR />
      </LinkWrapper>
      {isShowQR && (
        <QRCodeWrapper invert={invert} onClick={() => setShowQR(false)}>
          <Close />
          <BlockApp invert={invert}>
            <QrWrapper invert={invert}>{selectQrCode(app.qr, 'as')}</QrWrapper>
            <TitleQR invert={invert}>App Store</TitleQR>
          </BlockApp>
          <BlockApp invert={invert}>
            <QrWrapper invert={invert}>{selectQrCode(app.qr, 'gp')}</QrWrapper>
            <TitleQR invert={invert}>Google Play</TitleQR>
          </BlockApp>
        </QRCodeWrapper>
      )}
    </>
  );
};
export default CardApp;
