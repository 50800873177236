import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

const Row = styled.div`
  background: ${props => props.theme.primary_petrol};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
  padding: 40px 16px;
  background: ${props => props.theme.primary_petrol};
  ${media.laptop`
    flex-direction: row;
    padding: 50px 100px;
  `};
  ${media.desktop`
    width: 1240px;
  `};
  ${media.widescreen`
    width: 1440px;
  `};
`;

const Text = styled.span`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  ${media.laptop`
    font-size: 16px;
    line-height: 24px;
  `};
  ${media.ultrawidescreen`
    font-size: 22px;
    line-height: 36px;
  `};
`;

const Description = styled(Text)`
  width: 100%;
  color: ${props => props.theme.white};
  margin-bottom: 24px;
  a {
    text-decoration: none;
    color: ${props => props.theme.secondary_blue_light};
    margin-left: 5px;
  }
  ${media.laptop`
    width: 580px;
    margin-bottom: 0;
  `};
  ${media.ultrawidescreen`
    width: 768px;
  `};
`;

const Copyright = styled(Text)`
  color: ${props => props.theme.secondary_blue_light};
  width: 100%;
  text-align: left;
  ${media.desktop`
    width: 305px;
    text-align: right;
  `};
  ${media.laptop`
    width: 410px;
  `};
  ${media.widescreen`
    width: 540px;
  `};
`;

const Footer = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <Row>
      <Wrapper>
        <Description
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Footer.description,
          }}
        />
        <Copyright
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Footer.copy,
          }}
        />
      </Wrapper>
    </Row>
  );
};

export default Footer;
