import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import Item from './Item';

const Row = styled.div`
  background: ${props => props.theme.primary_petrol};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  padding: 40px 16px;
  ${media.tablet`
    padding: 88px 0;
    justify-content: center;
  `};
  ${media.desktop`
    width: 1240px;
  `};
  ${media.widescreen`
    width: 1440px;
  `};
`;

const Title = styled.h2`
  width: 100%;
  font-size: 24px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  margin: 0;
  margin-bottom: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${props => props.theme.white};
  ${media.laptop`
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 56px;
  `}
  ${media.widescreen`
    margin-bottom: 72px;
  `}
`;

const Dealers = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const { items, title } = Locales[lang].Dealers;

  return (
    <Row>
      <Wrapper>
        <Title>{title}</Title>
        {items.map(({ name, href, classForCommercialName }) => (
          <Item
            key={name}
            title={name}
            href={href}
            classForCommercialName={classForCommercialName}
          />
        ))}
      </Wrapper>
    </Row>
  );
};

export default Dealers;
