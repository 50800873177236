import React from 'react';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

const Svg = styled.svg`
  fill: ${props =>
    props.invert
      ? props.theme.secondary_blue_dark
      : props.theme.secondary_blue_light};
  
  width: 56px;
  bottom: 20px;
  right: 20px;
  position: ${props => (props.wideBlock ? 'absolute' : 'static')};
  ${media.mobile`
    width: 56px;
  `}
  ${media.phablet`
    width: 56px;
    height: 56px;
  `}
  ${media.tablet`
    position: static;;
  `}
`;

export default ({ wideBlock }) => (
  <Svg
    wideBlock={wideBlock}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    fill="#006384"
  >
    <path d="M33.3603 63.9189L32.8003 63.3518L35.2806 59.5443L24.0006 46.6633L8.80029 63.9999L8.32029 63.3518L24.0806 45.6101L36.4006 59.5443L33.3603 63.9189Z" />
    <path d="M23.7611 53.5493C24.6448 53.5493 25.3611 52.8239 25.3611 51.9291C25.3611 51.0342 24.6448 50.3088 23.7611 50.3088C22.8775 50.3088 22.1611 51.0342 22.1611 51.9291C22.1611 52.8239 22.8775 53.5493 23.7611 53.5493Z" />
    <path d="M24.641 46.2584L23.841 46.1774C24.081 43.99 23.841 41.8837 23.201 40.1014C22.961 39.4533 22.721 39.0483 22.561 38.6432C22.241 38.0761 22.001 37.59 21.921 36.8609C21.681 35.2407 22.241 34.2685 22.881 33.1343C23.281 32.4052 23.761 31.5141 24.161 30.2179C24.721 28.1926 24.641 26.4913 24.561 25.1141C24.481 23.4128 24.401 22.1166 25.601 21.1445C26.001 20.8204 27.041 20.0913 28.161 20.4964C29.041 20.7394 29.521 21.5495 29.761 22.0356L29.521 23.1698C29.521 22.4407 28.561 21.5495 27.921 21.3065C27.121 21.0634 26.401 21.6305 26.081 21.8736C25.201 22.5217 25.281 23.5748 25.361 25.1141C25.441 26.5723 25.521 28.3546 24.961 30.5419C24.561 31.9191 24.081 32.8103 23.601 33.6204C22.961 34.6736 22.561 35.4837 22.721 36.8609C22.801 37.509 23.041 37.9141 23.281 38.4002C23.441 38.8052 23.681 39.2103 24.001 39.9394C24.721 41.7217 24.961 43.909 24.641 46.2584Z" />
    <path d="M35.9199 56.547V55.7369C37.4399 55.6559 40.3999 55.2508 42.7999 52.9824C43.7599 52.0913 44.5599 50.9571 45.1199 49.7419L45.8399 50.066C45.1999 51.4432 44.3999 52.5774 43.3599 53.5495C40.6399 56.0609 37.5199 56.466 35.9199 56.547Z" />
    <path d="M52.0012 23.4936L51.6012 22.7645C53.8412 21.3873 54.9612 19.524 54.6412 18.5519C54.4812 18.1468 54.1612 17.7417 53.6812 17.5797C52.7212 17.2557 51.8412 18.1468 51.7612 18.1468L51.2012 17.4987C51.2812 17.4177 52.4812 16.2835 53.9212 16.7696C54.7212 17.0126 55.2012 17.7417 55.4412 18.3088C55.9212 19.7671 54.4012 21.9544 52.0012 23.4936Z" />
    <path d="M50.5601 31.3521C50.2401 31.3521 49.9201 31.2711 49.6801 31.109C49.2001 30.866 48.8001 30.4609 48.5601 30.0559C48.4001 29.7318 48.3201 29.4888 48.2401 29.1647C48.0801 28.3546 48.4801 27.4635 49.2001 26.9774L51.7601 25.1141C52.0801 24.8711 52.4801 24.709 52.8801 24.709C53.6001 24.628 54.3201 24.8711 54.8801 25.4382C55.3601 25.9242 55.6001 26.4913 55.5201 27.1394C55.5201 27.8685 55.1201 28.5166 54.6401 28.9217L52.0001 30.866C51.6001 31.1901 51.1201 31.3521 50.5601 31.3521ZM49.6801 27.6255C49.2001 27.9495 48.9601 28.5166 49.0401 29.0027C49.0401 29.2457 49.1201 29.4078 49.2801 29.6508C49.4401 29.9749 49.7601 30.2179 50.0801 30.3799C50.5601 30.623 51.1201 30.5419 51.6001 30.1369L54.1601 28.1926C54.5601 27.9495 54.7201 27.4635 54.8001 27.0584C54.8001 26.6533 54.6401 26.2483 54.4001 26.0052C54.0001 25.6812 53.5201 25.4382 53.0401 25.5192C52.8001 25.5192 52.5601 25.6002 52.4001 25.7622L49.6801 27.6255Z" />
    <path d="M49.6812 37.1848C49.3612 37.1848 49.0412 37.1038 48.8012 36.9418C48.3212 36.6988 47.9212 36.2937 47.6812 35.8886C47.5212 35.6456 47.4412 35.3215 47.3612 34.9975C47.2012 34.1874 47.6012 33.2962 48.3212 32.8102L50.8812 30.9469C51.2012 30.7038 51.6012 30.5418 52.0012 30.5418C52.7212 30.4608 53.4412 30.7038 54.0012 31.2709C54.4812 31.757 54.7212 32.3241 54.6412 32.9722C54.6412 33.7013 54.2412 34.3494 53.7612 34.7545L51.2012 36.6988C50.7212 37.0228 50.2412 37.1848 49.6812 37.1848ZM52.1612 31.3519C52.0812 31.3519 52.0812 31.3519 52.0012 31.3519C51.7612 31.3519 51.5212 31.4329 51.3612 31.595L48.8012 33.4583C48.3212 33.7823 48.0812 34.3494 48.1612 34.8355C48.1612 35.0785 48.2412 35.2405 48.4012 35.4836C48.5612 35.8076 48.8812 36.0507 49.2012 36.2127C49.6812 36.4557 50.2412 36.3747 50.7212 35.9696L53.2812 34.0253C53.6812 33.7823 53.8412 33.2962 53.9212 32.8912C53.9212 32.4861 53.7612 32.081 53.5212 31.838C53.0412 31.514 52.5612 31.3519 52.1612 31.3519Z" />
    <path d="M51.5206 40.8305C51.4406 40.8305 51.4406 40.8305 51.3606 40.8305C49.1206 40.7495 48.6406 39.6153 48.6406 38.6432C48.6406 37.59 49.3606 36.4558 51.3606 36.4558C53.8406 36.4558 54.0806 37.9951 54.0806 38.6432C54.0806 39.2102 53.8406 39.7773 53.5206 40.1014C53.1206 40.5875 52.4006 40.8305 51.5206 40.8305ZM51.3606 37.3469C50.0806 37.3469 49.4406 37.833 49.4406 38.7242C49.4406 39.2913 49.6806 40.0204 51.3606 40.1014C52.0806 40.1014 52.6406 39.9394 52.9606 39.6153C53.2006 39.3723 53.2806 39.0482 53.2806 38.7242C53.3606 38.3191 53.3606 37.3469 51.3606 37.3469Z" />
    <path d="M47.7604 50.39H33.6804C31.3604 50.39 29.4404 48.4457 29.4404 46.0963V14.8254C29.4404 12.476 31.3604 10.5317 33.6804 10.5317H47.8404C50.0804 10.5317 52.0004 12.476 52.0004 14.8254V25.9241H50.8804V14.7444C50.8804 13.0431 49.5204 11.5849 47.7604 11.5849H33.6804C32.0004 11.5849 30.5604 12.9621 30.5604 14.7444V46.0153C30.5604 47.7165 31.9204 49.1748 33.6804 49.1748H47.8404C49.5204 49.1748 50.9604 47.7976 50.9604 46.0153V40.6684H52.0004V46.0153C52.0004 48.4457 50.0804 50.39 47.7604 50.39Z" />
    <path d="M51.1203 42.2888H30.3203V43.423H51.1203V42.2888Z" />
    <path d="M51.1203 15.3926H30.3203V16.5268H51.1203V15.3926Z" />
    <path d="M47.3608 28.4354H42.8008V23.8987H47.3608V28.4354ZM44.0008 27.3012H46.3208V25.0329H44.0008V27.3012Z" />
    <path d="M40.8014 47.3113C41.5525 47.3113 42.1614 46.6947 42.1614 45.9341C42.1614 45.1735 41.5525 44.5569 40.8014 44.5569C40.0503 44.5569 39.4414 45.1735 39.4414 45.9341C39.4414 46.6947 40.0503 47.3113 40.8014 47.3113Z" />
    <path d="M33.2001 24.5466H32.0801V20.739H35.7601V21.8732H33.2001V24.5466Z" />
    <path d="M49.68 24.4656H48.56V21.8732H46V20.739H49.68V24.4656Z" />
    <path d="M35.7601 39.5342H32.0801V35.7266H33.2001V38.4H35.7601V39.5342Z" />
    <path d="M49.28 39.6153H46V38.4811H48.56V36.4558H49.28V39.6153Z" />
    <path d="M38.8804 28.4354H34.4004V23.8987H38.9604V28.4354H38.8804ZM35.5204 27.3012H37.8404V25.0329H35.5204V27.3012Z" />
    <path d="M38.8803 36.3744H34.3203V31.8376H38.8803V36.3744ZM35.4403 35.2402H37.7603V32.9718H35.4403V35.2402Z" />
    <path d="M36.3206 29.0835H34.6406V30.7037H36.3206V29.0835Z" />
    <path d="M40.9601 30.5416H38.0801V29.4074H39.8401V26.0859H41.3601V24.6277H42.4801V27.2201H40.9601V30.5416Z" />
    <path d="M46.5602 32.1621H44.0802V30.3798H42.1602V29.2456H45.2002V31.0279H46.5602V32.1621Z" />
    <path d="M41.0399 35.8076H39.9199V33.1341H41.9999V32.405H43.1199V34.2683H41.0399V35.8076Z" />
    <path d="M47.0397 37.1849H43.6797V36.0507H45.9197V33.6204H47.0397V37.1849Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.2 0.810127H0.8V63.1899H63.2V0.810127ZM0 0V64H64V0H0Z"
    />
  </Svg>
);
