import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import LoupeImage from 'Src/assets/img/ico/icon_search.svg';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-right: 0;
  margin-bottom: 8px;
  background: ${props => props.theme.secondary_blue_light};
  font-family: ${props => props.theme.fonts.font_head};
  color: ${props => props.theme.white};
  ${media.tablet`
    width: 372px;
    height: 630px;
    margin-left: ${props => (props.fullWidth ? '16px' : '0')};
    margin-right: 16px;
    margin-bottom: 0;
    padding: 40px;
  `}

  ${media.laptop`
    margin-right: 24px;
  `}
  ${media.widescreen`
    width: 392px;
    height: 630px;
  `}
  ${media.ultrawidescreen`
    width: 456px;
    height: 650px;
  `}
`;

const TitleBlock = styled.div``;

const Title = styled.h3`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;
  margin: 0;
  br {
    display: none;
  }
  ${media.mobile`
    font-size: 22px;
  `}
  ${media.tablet`
    font-size: 30px;
    br {
      display: block;
    }
  `}
  ${media.ultrawidescreen`
    font-size: 32px;
    line-height: 40px;
  `}
`;

const Loupe = styled.img`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 56px;
  ${media.tablet`
    position: static;
    width: 112px;
  `}
`;

const PhoneLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: ${props => props.theme.white};
  &:hover {
    color: ${props => props.theme.primary_petrol};
  }
  ${media.tablet`
    font-size: 22px;
    line-height: 36px;
  `}
`;

const HotLine = () => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <Wrapper>
      <TitleBlock>
        <Loupe src={LoupeImage} />
        <Title
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Contacts.hotline,
          }}
        />
      </TitleBlock>
      <PhoneLink href={`tel:${Locales[lang].Contacts.phone}`}>
        {Locales[lang].Contacts.formatPhone}
      </PhoneLink>
    </Wrapper>
  );
};

export default HotLine;
