import React from 'react';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import HotLine from './HotLine';
import MobileApps from './MobileApps';

const Wrapper = styled.div`
  padding: 30px 16px;
  ${media.tablet`
    padding: 30px 0;
  `}
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  ${media.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
  `}
`;

const Dealers = () => {
  return (
    <Wrapper>
      <StyledRow>
        <HotLine />
        <MobileApps />
      </StyledRow>
    </Wrapper>
  );
};

export default Dealers;
